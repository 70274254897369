import { defineStore } from "pinia";
import { getDefaultPreFiltrationFormApp } from "@/utils/getters/defaultFilters";
import { reactive, ref } from "vue";
import { Tabs } from "@/router/tabs";

import type { PreFiltrationFormAppI } from "@/utils/getters/defaultFilters";
import type { ApplicationsTab } from "@/router/tabs";
import useTemplatesStore from "~/stores/templates/useTemplatesStore";
import useSearchStore from "~/stores/search/useSearchStore";

function getDefaultPageData() {
  return {
    selectedTemplatesIds: [],
    preFiltrationForm: getDefaultPreFiltrationFormApp(),
  }
}

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useApplicationsStore = defineStore('applicationsStore', () => {

  const tab = ref(Tabs.Applications.Main);
  const pageStatuses = ref<number[]>([]);

  const selectedTemplatesIds = ref<number[]>([]);  // number[] включая -2 если выбраны 'Закупки без шаблона'
  const preFiltrationForm = ref<PreFiltrationFormAppI>(getDefaultPreFiltrationFormApp());

  const searchStore = useSearchStore();
  const templatesStore = useTemplatesStore();

  // выбранные шаблоны и фильтры по вкладкам
  const pages = reactive({
    application: getDefaultPageData(),
    calculation: getDefaultPageData(),
    participation: getDefaultPageData(),
    bargaining: getDefaultPageData(),
  })

  function updatePages() {
    pages[tab.value].selectedTemplatesIds = selectedTemplatesIds.value;
    pages[tab.value].preFiltrationForm = preFiltrationForm.value;
  }

  function setDataOnMount(t: ApplicationsTab) {
    // если были выбраны удаленные шаблоны или теги, их нужно убрать
    pages[t].selectedTemplatesIds = pages[t].selectedTemplatesIds.filter(e => !!templatesStore.templatesList.find(t => t.id === e) || e === -2)
    pages[t].preFiltrationForm.tagIds = pages[t].preFiltrationForm.tagIds.filter(e => !!searchStore.tags.find(t => t.id === e))

    selectedTemplatesIds.value = pages[t].selectedTemplatesIds;
    preFiltrationForm.value = pages[t].preFiltrationForm;
  }

  function resetApplicationStore() {
    tab.value = Tabs.Applications.Main
    pageStatuses.value = [];

    pages.application = getDefaultPageData()
    pages.calculation = getDefaultPageData()
    pages.participation = getDefaultPageData()
    pages.bargaining = getDefaultPageData()

    selectedTemplatesIds.value = [];
    preFiltrationForm.value = getDefaultPreFiltrationFormApp();
  }

  function getPageStatuses(t: ApplicationsTab) {
    switch (t) {
      case Tabs.Applications.Main: return [];
      case Tabs.Applications.Calculation: return [1];
      case Tabs.Applications.Participation: return [2];
      case Tabs.Applications.Bargaining: return [3];
    }
  }

  function setTab(t: ApplicationsTab) {
    tab.value = t;
    pageStatuses.value = getPageStatuses(t);
    setDataOnMount(t)
  }

  return {
    pages, // не удалять
    pageStatuses,
    selectedTemplatesIds,
    preFiltrationForm,
    updatePages,
    setTab,
    resetApplicationStore,
  }
}, { persist: true })

export default useApplicationsStore;
