import cloneDeep from "lodash/cloneDeep";
import type { ExtendedTemplateI, TemplateI } from "@/stores/templates/SearchTemplatesInterface";
import type { TemplateManagerI } from "~/stores/auth/UserInterface";
import { getFullName } from "~/utils/formatter/nameFormatter";

function getTemplateResponsible(responsible: any) : TemplateManagerI {
  return {
    id: responsible.id,
    firstName: responsible.firstName,
    middleName: responsible.middleName,
    surName: responsible.surName,
    fio: getFullName(responsible),
    emailForNotification: responsible.emailSettings?.email || '',
    enableNotifications: responsible.emailSettings?.enableNotifications || false,
  }
}

export function getTemplateInfoBasic(template: any, groupId = 0, unseen = 0) : TemplateI {
  return {
    id: template.id,
    title: template.title,
    type: template.type?.id || 1,
    responsible: template.responsiblePerson ? getTemplateResponsible(template.responsiblePerson) : null,
    unseen: unseen,
    groupId: groupId,
  }
}

export function getTemplateInfoExtended(template: any) : ExtendedTemplateI {
  return {
    id: template.id,
    title: template.title,
    type: template.type?.id || 1,
    groupId: template.group?.id || null,
    responsible: template.responsiblePerson || null,
    templateMailingSchedule: template.templateMailingSchedule ? template.templateMailingSchedule.map(e => e.id) : [],
  }
}

export function getDefaultTemplateData() : ExtendedTemplateI {
  return {
    id: null,
    title: '',
    type: 1,
    groupId: null,
    responsible: null,
    templateMailingSchedule: [],
  }
}

export function getCopyMeta(m: ExtendedTemplateI) : ExtendedTemplateI {
  return cloneDeep({
    id: null,
    title: `${ m.title } (копия)`,
    type: 1,
    groupId: m.groupId,
    responsible: m.responsible,
    templateMailingSchedule: m.templateMailingSchedule,
  })
}

export function getEditMeta(m: ExtendedTemplateI) : ExtendedTemplateI {
  return cloneDeep({
    ...m,
  })
}
