<template lang="pug">
.order-form
  .inputs
    validation-row.order-name(
      not-empty
      required
      ref="refName"
      v-model="form.name"
      label="Контактное лицо"
      placeholder="Введите имя"
      @error="errorChecker('order_name')"
      @correct="correctChecker('order_name')"
    )
    timezone-selector.order-timezone(
      required
      ref="refTimezone"
      v-model="form.timezone"
      @error="errorChecker"
      @correct="correctChecker"
    )
    validation-row.order-phone(
      required
      not-empty
      type-phone
      ref="refPhone"
      v-model="form.phoneNumber"
      label="Телефон"
      placeholder="Введите телефон"
      @error="errorChecker('order_phone')"
      @correct="correctChecker('order_phone')"
    )
    validation-row.order-email(
      type-email
      ref="refEmail"
      v-model="form.email"
      label="Email"
      placeholder="Введите email"
      :asterisk="false"
      @error="errorChecker('order_email')"
      @correct="correctChecker('order_email')"
    )
  form-textarea(
    v-if="showTextarea"
    v-model="form.comment"
    label="Комментарий"
    placeholder="Оставьте комментарий"
    :max-length="255"
    :show-word-limit="true"
  )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useVModel } from "@vueuse/core";
import { useForm } from "@/use/other/useForm";
import FormTextarea from "@/components/ui/form/input/FormTextarea.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import TimezoneSelector from "@/components/selector/TimezoneSelector.vue";
import type { PropType } from "vue";
import type { OrderFormI } from "@/use/tenderService/TenderServiceInterface";

export default defineComponent({
  name: "OrderForm",
  components: {
    FormTextarea,
    ValidationRow,
    TimezoneSelector,
  },
  emits: [
    'confirm',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<OrderFormI>,
      default: () => {},
    },
    showTextarea: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const refName = ref();
    const refPhone = ref();
    const refEmail = ref();
    const refTimezone = ref();

    const form = useVModel<OrderFormI>(props, 'modelValue', context.emit);

    const { validateForm, errorChecker, correctChecker }
      = useForm(() => context.emit('confirm'));

    function validate() {
      refName.value.validate()
      refPhone.value.validate()
      refEmail.value.validate()
      refTimezone.value.validate()
      validateForm()
    }

    return {
      form,
      refName,
      refPhone,
      refEmail,
      refTimezone,
      validate,
      errorChecker,
      correctChecker,
    }
  }
})
</script>

<style scoped lang="scss">
.order-form {
  display: flex;
  flex-flow: column;
  gap: 16px;
  font-size: 14px;

  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 16px;
    row-gap: 16px;

    grid-template-areas: "order-name     order-timezone"
                         "order-phone    order-email";

    .order-name { grid-area: order-name; }
    .order-timezone { grid-area: order-timezone; }
    .order-phone { grid-area: order-phone; }
    .order-email { grid-area: order-email; }
  }
}

@media (max-width: 640px) {
  .order-form {
    .inputs {
      display: flex;
      flex-flow: column;
    }
  }
}
</style>
