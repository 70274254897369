<template lang="pug">
.mini-loader(:style="`width: ${ size }px; height: ${ size }px;`")
  .is-loading
    ui-icon(:size="size" :icon="UiIconNames.Icon_Loading")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "MiniLoader",
  components: {
    UiIcon,
  },
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.mini-loader {
  .is-loading {
    display: flex;
    width: inherit;
    height: inherit;
    animation: rotating 2s linear infinite;
  }
}
</style>
