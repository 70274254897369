<template lang="pug">
ClientOnly
  el-tooltip(:content="tooltipTitle" :placement="tooltipPosition || 'bottom'" effect="light" :disabled="disabled || tooltipTitle === ''" :hide-after="50")
    button.icon-button(v-if="!asButton" :class="[disabled && '_disabled', $props.class, type]" :disabled="disabled" @click="$emit('click', $event)" type="button")
      ui-icon(:icon="icon" :size="size")

    ui-button(v-else :loader="loader" :type="buttonType" :size="buttonSize" :disabled="disabled" :class="$props.class" :icon-left="loader ? '' : icon" @click="$emit('click', $event)")
      slot
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";

export default defineComponent({
  components: {
    UiIcon,
    UiButton,
    MiniLoader,
  },
  emits: [
    'click',
  ],
  props: {
    icon: String,
    tooltipPosition: {
      type: String as PropType<"top" | "right" | "bottom" | "left">,
      default: "bottom",
    },
    tooltipTitle: {
      type: String,
      default: "",
    },
    class: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 20
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String as PropType<"primary" | "secondary" | "tertiary" | "link">,
      default: "primary",
    },
    buttonSize: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "medium",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'default'|'danger'>,
      default: 'default',
    },
  },
})
</script>

<style scoped lang="scss">
.icon-button {
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
  border-radius: 4px;
  align-items: center;
  padding: 0 6px;

  &._disabled {
    color: var(--main-color-grey-disabled);
    cursor: default;
  }

  &.default:not(._disabled) {
    color: var(--main-color-blue);
  }

  &.danger:not(._disabled) {
    color: var(--main-red-color);
  }
}
</style>
