<template lang="pug">
LogoMini.logo(v-if="mini")
Logo.logo(v-else)
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "assets/logo/LOGO.svg?component";
import LogoMini from "assets/logo/LOGOmini.svg?component";

export default defineComponent({
  name: "UiLogo",
  components: {
    Logo,
    LogoMini,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.logo {
  height: 32px;
}
</style>
