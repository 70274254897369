import { computed, ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";
import useAuthStore from "@/stores/auth/useAuthStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import cloneDeep from "lodash/cloneDeep";
import type { CompanyItemI } from "@/stores/auth/UserInterface";

export function getDefaultCompany() : CompanyItemI {
  return {
    id: 0,
    title: '',
    fullTitle: '',
    inn: '',
    kpp: '',
    ogrn: '',
    legalAddress: '',
    postAddress: '',
    factAddress: '',
  }
}

export function useCompanyManagement() {

  const authStore = useAuthStore()
  const { company } = storeToRefs(authStore);

  const { managerCompanyId, managerCompanyTitle } = useSuperAccount();
  const { isTmManager } = useUserAccess();

  const myCompanyId = computed(() =>
    isTmManager.value && managerCompanyId.value ? managerCompanyId.value : authStore.company?.id
  );

  const managerStore = useTmManagerStore();
  const { clientCompanyInfo, selectedCompanyInfo } = storeToRefs(managerStore);
  const { formatCompanyData } = managerStore;

  function getDefaultSettCompany() {
    return {
      ...getDefaultCompany(),
      id: myCompanyId.value,
      title: (isTmManager.value ? managerCompanyTitle.value : authStore.company?.title) || '',
    }
  }

  const editableCompany = ref<CompanyItemI>(isTmManager.value ? cloneDeep(clientCompanyInfo.value) : getDefaultSettCompany())

  const isFetching = ref(false)
  const isSaving = ref(false)

  function getMyCompany() {
    isFetching.value = true;

    return new Promise((resolve, reject) => {

      useApi().companies.fetchCompanyInfo(myCompanyId.value)
        .then((data) => {
          editableCompany.value = formatCompanyData(data)
          resolve(editableCompany.value)
        })
        .catch(() => reject(null))
        .finally(() => isFetching.value = false)
    })
  }

  function saveMyCompany() {
    isSaving.value = true;

    return new Promise((resolve, reject) => {

      useApi().companies.patchCompanyInfo(myCompanyId.value, editableCompany.value)
        .then(() => {
          if (!isTmManager.value) company.value.title = editableCompany.value.title
          else {
            clientCompanyInfo.value = editableCompany.value
            if (selectedCompanyInfo.value) selectedCompanyInfo.value.title = editableCompany.value.title
          }
          useNotificationsStore().showSuccess()
          resolve(null);
        })
        .catch(() => useNotificationsStore().showError())
        .finally(() => isSaving.value = false)

    });
  }

  return {
    editableCompany,
    isFetching,
    isSaving,
    getMyCompany,
    saveMyCompany,
  };
}
