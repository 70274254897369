<template lang="pug">
transition-group.notifications-container(name="notification" tag="div")
  notification(
    v-for="item in notifications"
    :key="item.id"
    :notification="item"
    @close-notification="closeNotification(item.id)"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";

import Notification from "~/globals/notifications/Notification.vue";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export default defineComponent({
  name: "Notifications",
  components: {
    Notification,
  },

  setup() {
    const notificationsStore = useNotificationsStore();
    const { notifications } = storeToRefs(notificationsStore);

    function closeNotification(id: string) {
      notificationsStore.deleteNotification(id);
    }

    return {
      notifications,
      closeNotification,
    };
  },
});
</script>

<style scoped lang="scss">
.notifications-container {
  margin-left: auto;
  position: fixed;
  z-index: 9999;
  bottom: 56px;
  right: 12px;
  width: 321px;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  .notification-move,
  .notification-enter-active,
  .notification-leave-active {
    transition: all 0.3s ease;
  }

  .notification-enter,
  .notification-leave-to {
    opacity: 0;
    transform: translateX(20px);
  }
}
</style>
