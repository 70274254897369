<template lang="pug">
.notification(:class="`_${notification.type}`")
  .header
    span {{ notification.title }}
    ui-icon(clickable :size="16" :icon="UiIconNames.Icon_Close" @click="$emit('closeNotification')")
  .content {{ notification.message }}
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";
import type { NotificationI } from "~/stores/systemNotifications/NotificationInterface";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiIconNames from "~/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "Notification",
  components: {
    UiIcon,
  },
  emits: [
    'closeNotification',
  ],
  props: {
    notification: {
      type: Object as PropType<NotificationI>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';
$notification_width: 300px;

.notification {
  color: var(--main-text-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: $notification_width;
  background: var(--main-color-grey-popup);
  padding: 16px 24px;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 4px;

  &._error {
    background-color: #ffeeee;
    border: 1px solid var(--main-red-color);
  }

  &._warning {
    background-color: #fff6ec;
    border: 1px solid #ff9c41;
  }

  &._success {
    background-color: #e5f5f0;
    border: 1px solid #11a075;
  }

  &.info {
    background-color: #f3fafb;
    border: 1px solid var(--main-color-blue);
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }

  .content {
    @include label-13-16;
    width: 100%;
    color: var(--main-text-color);

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
