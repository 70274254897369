<template lang="pug">
router-link.link(:target="target" :to="`/search/detail/${id}`")
  slot № {{ number }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from "vue";

export default defineComponent({
  name: "LotLink",
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    number: {
      type: [Number, String],
      default: '',
    },
    target: {
      type: String as PropType<'_self'|'_blank'>,
      default: '_blank',
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}
</style>
