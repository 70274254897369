import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#000\" d=\"M12.628 13.036V21h-1.812v-7.964h1.812Zm2.625 0v1.429H8.239v-1.429h7.014Zm1.986 0h1.524l2.146 5.626 2.14-5.626h1.525L21.518 21H20.3l-3.062-7.964Zm-.848 0h1.531l.28 6.009V21h-1.811v-7.964Zm7.513 0h1.538V21h-1.819v-1.955l.28-6.009Z\"></path><path fill=\"#00B8FF\" d=\"M12.021 31.497A16 16 0 0 0 28.328 26.2l-2.835-2.346a12.32 12.32 0 0 1-12.557 4.08l-.915 3.564Z\"></path><path fill=\"#96DDFF\" d=\"M10.11 1.124A16 16 0 0 0 .032 14.995l3.672.231a12.32 12.32 0 0 1 7.76-10.68L10.11 1.123Z\"></path><path fill=\"#56C8FF\" d=\"M.032 17.005A16 16 0 0 0 10.11 30.876l1.355-3.421a12.32 12.32 0 0 1-7.76-10.681l-3.673.23Z\"></path><path fill=\"#B6EBFF\" d=\"M28.328 5.801A16 16 0 0 0 12.021.503l.915 3.564a12.32 12.32 0 0 1 12.557 4.08L28.328 5.8Z\"></path>", 5)
  ])))
}
export default { render: render }