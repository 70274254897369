<template lang="pug">
form-input-container(:label="label")
  ClientOnly
    el-select(
      class="ui-select include-padding"
      fit-input-width
      :disabled="disabled"
      :persistent="persistent"
      :teleported="teleported"
      :value-key="trackBy"
      :model-value="modelValue"
      :filterable="filterable"
      :clearable="clearable"
      :placeholder="placeholder"
      :automatic-dropdown="true"
      no-match-text="Нет совпадений"
      no-data-text="Нет данных"
      @update:modelValue="changeSelect"
    )
      el-option(
        v-for="item in options"
        :key="item[trackBy]"
        :label="item[field]"
        :value="fullInfo ? item : item[trackBy]"
      )
        slot(name="option" :item="item")
      template(#empty)
        span.no-data {{ noDataText }}
      template(v-if="$slots.footer" v-slot:footer)
        slot(name="footer")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cloneDeep from "lodash/cloneDeep";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";
import { ElSelect } from "element-plus";

export default defineComponent({
  name: "UiSelect",
  components: {
    ElSelect,
    FormInputContainer,
  },
  emits: [
    'update:modelValue',
    'changeSelect',
  ],
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    modelValue: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * отслеживаемое поле
     */
    trackBy: {
      type: String,
      default: "id",
    },
    /**
     * поле у выбранного значения и опций, которое выводится в селекторе
     */
    field: {
      type: String,
      default: "title",
    },
    label: {
      type: String,
      default: '',
    },
    /**
     * false - возвращать айдишник выбранной опции
     * true - возвращать весь элемент
     */
    fullInfo: {
      type: Boolean,
      default: false,
    },
    teleported: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "Нет данных",
    },
  },
  setup(props, context) {

    function changeSelect(value: any) {
      const oldValue = cloneDeep(props.modelValue);
      context.emit('update:modelValue', value)
      context.emit('changeSelect', { value: value, oldValue: oldValue })
    }

    return {
      changeSelect,
    }
  }
})
</script>

<style lang="scss">
.el-select-dropdown__item {
  height: auto !important;
  white-space: break-spaces !important;
  line-height: 20px !important;
  padding: 8px 20px !important;
}
</style>

<style scoped lang="scss">
@import '@/assets/styles/elements/selector';

.no-data {
  display: flex;
  color: var(--main-placeholder-color);
  margin: 12px 16px;
  font-size: 13px;
  justify-content: center;
}
</style>
