import { ref } from "vue";

export function useToggle() {

  const visible = ref<boolean>(false);

  const show = ():void => visible.value = true;
  const hide = ():void => visible.value = false;
  const toggle = ():void => visible.value = !visible.value;

  return {
    visible,
    show,
    hide,
    toggle,
  };
}
