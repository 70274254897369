export interface NotificationI {
  id: string;
  title: string;
  message: string;
  closeAfter: number;
  type: NotificationTypes;
}

export enum NotificationTypes {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}
