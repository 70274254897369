<template lang="pug">
.alert(v-if="open" :class="`_${ type }`")
  .header(v-if="label || slots.label")
    ._left
      ui-icon.margin(:size="14" :icon="UiIconNames.Icon_Info")
      span
        slot(name="label") {{ label }}
    ui-icon.margin(v-if="closable" clickable :size="14" :icon="UiIconNames.Icon_Close" @click="closeAlert")
  .content(v-if="message || slots.content" :class="!fullInfo && '_brief'")
    slot(name="content") {{ message }}
  slot
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, computed, ref, } from "vue";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "AlertMessage",
  components: {
    UiIcon,
  },
  emits: [
    'close',
  ],
  props: {
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'success'|'info'|'error'|'warning'|'default'>,
      default: 'default',
    },
    fullInfo: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const open = ref(true);
    const slots = computed(() => context.slots);

    function closeAlert() {
      context.emit('close')
      open.value = false
    }

    return {
      slots,
      open,
      closeAlert,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.alert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px 24px;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--main-color-blue-dark);
  background-color: #ecf5ff;

  .margin {
    margin-top: 2px;
  }

  &._error {
    background-color: #ffeeee;
    color: #e84343;
    border: 1px solid #e84343;
  }

  &._warning {
    background-color: #fff6ec;
    color: #ee802c;
    border: 1px solid #ee802c;
  }

  &._success {
    background-color: #e5f5f0;
    color: #529b2e;
    border: 1px solid #529b2e;
  }

  &._info {
    background-color: #f4f4f5;
    color: #383a3e;
  }

  &._default {
    border: 1px solid #409eff;
  }

  .header {
    display: flex;
    flex-flow: row;
    gap: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: 100%;

    ._left {
      display: flex;
      flex-flow: row;
      gap: 8px;
      width: 100%;
    }
  }

  .content {
    @include label-13-16;
    width: 100%;

    &._brief {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
