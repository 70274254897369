import { prepareNotEmptyPreFiltration } from "@/utils/searchForm/searchFormWorker";
import { getDefaultPreFiltrationFormClients, getDefaultPreFiltrationFormService } from "@/utils/getters/defaultFilters";
import { format } from "date-fns";

const accord = {
  serviceNameId: 'consultingService.id',
  serviceStatusIds: 'status.id[]',
  govRuId: 'lot.govRuId',
  page: 'page',
  customerCompany: 'company.id',
}

const historyAccord = {
  page: 'page',
  customerCompany: 'recipient.user.company.id',
  notificationTypes: 'notificationType.id[]',
}

const companiesAccord = {
  login: 'users.securityUser.login',
  manager: 'companyManagers.manager.id',
}

export function serviceFiltersToQuery(f: any) {
  const filters = prepareNotEmptyPreFiltration(f, getDefaultPreFiltrationFormService())

  let res = []

  for (const key of Object.keys(filters)) {
    if (accord.hasOwnProperty(key)) {
      switch(key) {
        case 'serviceStatusIds':
          const a = [
            ...filters[key].includes(-1) ? [5, 6] : [],
            ...filters[key].filter(e => e !== -1)
          ]

          // res.push(...a.map(elem => [accord[key], elem].join('=')));
          a.forEach((elem) => res.push([accord[key], elem].join('=')))
          break;
        case 'customerCompany':
          res.push([accord[key], filters[key].id].join('='))
          break;
        default:
          res.push([accord[key], filters[key]].join('='))
          break;
      }
    }
  }

  if (filters.sorting?.length) {
    filters.sorting?.forEach(e => res.push(`order[${e.title}]=${e.order}`))
    // res.push(...filters.sorting?.map(e => `order[${e.title}]=${e.order}`) || [])
  }

  return res.length ? '?' + res.join('&') : ''
}

export function notificationFiltersToQuery(f: any) {
  let res = []

  for (const key of Object.keys(f)) {
    if (historyAccord.hasOwnProperty(key) && f[key]) {
      switch(key) {
        case 'notificationTypes':
          // res.push(...f[key].map((elem) => [historyAccord[key], elem].join('=')) || [])
          f[key].forEach((elem) => res.push([historyAccord[key], elem].join('=')))
          break;
        case 'customerCompany':
          res.push([historyAccord[key], f[key].id].join('='))
          break;
        default:
          res.push([historyAccord[key], f[key]].join('='))
          break;
      }
    }
  }

  if (f.sorting?.length) {
    res.push(f.sorting?.map(e => `order[${e.title}]=${e.order}`))
  }

  return res.length ? '?' + res.join('&') : ''
}

export function companiesFiltersToQuery(f: any) {
  const filters = prepareNotEmptyPreFiltration(f, getDefaultPreFiltrationFormClients())

  let res = []

  for (const key of Object.keys(filters)) {
    if (companiesAccord.hasOwnProperty(key)) {
      if (key === 'manager') res.push([companiesAccord[key], filters[key].id].join('='))
      else res.push([companiesAccord[key], filters[key]].join('='))
    } else if (key === 'dateEndTariff' || key === 'dateCreate') {
      if (filters[key]?.dateFrom) res.push(`${key}[after]=${format(filters[key]?.dateFrom, 'yyyy-MM-dd')}`)
      if (filters[key]?.dateTo) res.push(`${key}[before]=${format(filters[key]?.dateTo, 'yyyy-MM-dd')}`)
    } else if (key !== 'sorting') {
      res.push([key, filters[key]].join('='))
    }
  }

  if (filters.sorting?.length) {
    res.push(...filters.sorting?.map(e => `order[${e.title}]=${e.order}`) || [])
  }

  return res.length ? '?' + res.join('&') : ''
}
