import { ref } from "vue";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

export function useForm(saveFormContinue: () => void, errMessage?: string) {

  const errList = ref<string[]>([]);
  const messageType = ref("info");
  const updateResult = ref("");

  function errorChecker(e: string): void {
    !errList.value.includes(e) && errList.value.push(e);
  }

  function correctChecker(e: string): void {
    errList.value = errList.value.filter(err => err != e)
  }

  function validateForm(): boolean {
    if (errList.value.length === 0) {
      updateResult.value = "сохранено";
      saveFormContinue();
      return true;
    } else {
      useNotificationsStore().showWarning(errMessage)
      messageType.value = "danger";
      updateResult.value = "исправьте ошибки";
      return false;
    }
  }

  return {
    errorChecker,
    correctChecker,
    validateForm,
    updateResult,
    errList,
    messageType,
  };
}
