import type { RouterConfig } from '@nuxt/schema'
import { Tabs, TrainingArticles } from "@/router/tabs";

export const Layouts = {
  Default: 'default',
  Dashboard: 'dashboard',
  Custom: 'custom',
} as const;

export default <RouterConfig> {
  routes: (_routes) => [
    {
      path: "/",
      name: Tabs.Landing,
      meta: { title: "Авторизация", layout: Layouts.Dashboard, requiresAuth: false },
      component: () => import("@/views/landing/Index.vue"),
    },
    {
      path: "/search/detail/:tLotId(\\d+)",
      name: Tabs.LotDetail,
      meta: { title: "О лоте", layout: Layouts.Custom, requiresAuth: false },
      component: () => import("@/views/detail/Index.vue"),
    },
    {
      path: "/:lotNumber(\\d+)/:govRuId",
      name: Tabs.LotGovDetail,
      meta: { title: "О лоте", layout: Layouts.Custom, requiresAuth: false },
      component: () => import("@/views/detail/Index.vue"),
    },
    // {
    //   path: "/:lotNumber(\\d+)/:govRuId(\\d+).html",
    //   redirect: () => {
    //     return { name: Tabs.LotGovDetail }
    //   },
    // },
    {
      path: "/search",
      name: Tabs.Search,
      meta: { title: "Поиск", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/search/Index.vue"),
    },
    {
      path: "/application",
      name: Tabs.Applications.Main,
      meta: { title: "Заявки", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/applications/Index.vue"),
    },
    {
      path: "/application/calculation",
      name: Tabs.Applications.Calculation,
      meta: { title: "Расчет заявок", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/applications/Index.vue"),
    },
    {
      path: "/application/filling",
      name: Tabs.Applications.Participation,
      meta: { title: "Подача заявок", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/applications/Index.vue"),
    },
    {
      path: "/application/bargaining",
      name: Tabs.Applications.Bargaining,
      meta: { title: "Торги", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/applications/Index.vue"),
    },
    {
      path: "/contracts",
      name: Tabs.Contracts.Main,
      meta: { title: "Контракты", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/contracts/Index.vue"),
    },
    {
      path: "/contracts/conclusion",
      name: Tabs.Contracts.Conclusion,
      meta: { title: "Контракты на заключении", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/contracts/Index.vue"),
    },
    {
      path: "/contracts/execution",
      name: Tabs.Contracts.Execution,
      meta: { title: "Контракты на исполнении", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/contracts/Index.vue"),
    },
    {
      path: "/contracts/completed",
      name: Tabs.Contracts.Completed,
      meta: { title: "Завершенные контракты", requiresAuth: true, searchGroup: true },
      component: () => import("@/views/contracts/Index.vue"),
    },
    {
      path: "/analytics",
      name: Tabs.Analytics.Main,
      meta: { title: "Аналитика", requiresAuth: true },
      component: () => import("@/views/analytics/Index.vue"),
      children: [
        {
          path: "industry/:subTab?",
          name: Tabs.Analytics.Industry,
          meta: { title: "Отрасль", requiresAuth: true },
          component: () => import("@/views/analytics/industry/Industries.vue"),
        },
        {
          path: "suppliers/:subTab?",
          name: Tabs.Analytics.Suppliers,
          meta: { title: "Поставщики", requiresAuth: true },
          component: () => import("@/views/analytics/suppliers/Suppliers.vue"),
        },
        {
          path: "customers/:subTab?",
          name: Tabs.Analytics.Customers,
          meta: { title: "Заказчики", requiresAuth: true },
          component: () => import("@/views/analytics/customers/Customers.vue"),
        },
      ],
    },
    {
      path: "/services",
      name: Tabs.Services,
      meta: { title: "Услуги", requiresAuth: true },
      component: () => import("@/views/orders/Index.vue"),
    },
    {
      path: "/support",
      name: Tabs.Training.Main,
      meta: { title: "Обучение", requiresAuth: true },
      component: () => import("@/views/support/Index.vue"),
    },
    {
      path: "/support/templates",
      name: Tabs.Training.Templates,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Templates)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/TemplatesArticle.vue")
    },
    {
      path: "/support/filters",
      name: Tabs.Training.Filters,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Filters)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/FiltersArticle.vue")
    },
    {
      path: "/support/lots",
      name: Tabs.Training.WatchedLots,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.WatchedLots)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/WatchedLotsArticle.vue")
    },
    {
      path: "/support/comments",
      name: Tabs.Training.Comments,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Comments)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/CommentsArticle.vue")
    },
    {
      path: "/support/applications",
      name: Tabs.Training.Apps,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Apps)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/AppsArticle.vue")
    },
    {
      path: "/support/contracts",
      name: Tabs.Training.Contracts,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Contracts)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/ContractsArticle.vue")
    },
    {
      path: "/support/tags",
      name: Tabs.Training.Tags,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.Tags)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/TagsArticle.vue")
    },
    {
      path: "/support/multi-actions",
      name: Tabs.Training.MultiCardActions,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.MultiCardActions)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/MultiCardActionsArticle.vue")
    },
    {
      path: "/support/tender-services",
      name: Tabs.Training.TenderServices,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.TenderServices)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/TenderServiceArticle.vue")
    },
    {
      path: "/support/history-notifications",
      name: Tabs.Training.NotificationHistory,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.NotificationHistory)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/NotificationHistoryArticle.vue")
    },
    {
      path: "/support/analytics-industry",
      name: Tabs.Training.IndustryAnalytics,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.IndustryAnalytics)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/AnalyticsIndustryArticle.vue")
    },
    {
      path: "/support/analytics-company",
      name: Tabs.Training.CompanyAnalytics,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.CompanyAnalytics)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/AnalyticsCompanyArticle.vue")
    },
    {
      path: "/support/account-management",
      name: Tabs.Training.AccountManagement,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.AccountManagement)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/AccountManagementArticle.vue")
    },
    {
      path: "/support/users-management",
      name: Tabs.Training.UserManagement,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.UserManagement)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/UserManagementArticle.vue")
    },
    {
      path: "/support/password-change",
      name: Tabs.Training.ChangePassword,
      meta: { title: TrainingArticles.find(e => e.key === Tabs.Training.ChangePassword)?.tabTitle || '', requiresAuth: true },
      component: () => import("@/views/support/PasswordArticle.vue")
    },
    {
      path: "/release-notes",
      redirect: () => { return { name: Tabs.ReleaseNotesFront }},
    },
    {
      path: "/release-notes/frontend",
      name: Tabs.ReleaseNotesFront,
      meta: { title: "Список изменений", requiresAuth: true },
      component: () => import("@/views/releaseNotes/FrontIndex.vue"),
    },
    {
      path: "/release-notes/backend",
      name: Tabs.ReleaseNotesBack,
      meta: { title: "Список изменений", requiresAuth: true },
      component: () => import("@/views/releaseNotes/BackIndex.vue"),
    },
    {
      path: "/settings",
      name: Tabs.Settings.Main,
      meta: { title: "Настройки", requiresAuth: true },
      component: () => import("@/views/settings/Index.vue"),
    },
    {
      path: "/settings/notifications",
      name: Tabs.Settings.Notifications,
      meta: { title: "Настройки уведомлений", requiresAuth: true },
      component: () => import("@/views/settings/notifications/NotificationPage.vue"),
    },
    {
      path: "/settings/account",
      name: Tabs.Settings.Account,
      meta: { title: "Управление аккаунтом", requiresAuth: true },
      component: () => import("@/views/settings/account/MyAccount.vue"),
    },
    {
      path: "/settings/company-management",
      name: Tabs.Settings.CompanyManagement,
      meta: { title: "Управление компанией", requiresAuth: true },
      component: () => import("@/views/settings/companyManagement/ManagementCompany.vue"),
    },
    {
      path: "/settings/system-management",
      name: Tabs.Settings.SystemManagement,
      meta: { title: "Системные настройки", requiresAuth: true },
      component: () => import("@/views/settings/systemManagement/SystemManagement.vue"),
    },
    {
      path: "/settings/users-management",
      name: Tabs.Settings.UsersManagement,
      meta: { title: "Управление пользователями", requiresAuth: true },
      component: () => import("@/views/settings/usersManagement/UserManagement.vue"),
    },
    {
      path: "/settings/tariffs",
      name: Tabs.Settings.Tariffs,
      meta: { title: "Управление тарифами", requiresAuth: true },
      component: () => import("@/views/settings/tariff/TariffPage.vue"),
    },
    {
      path: "/settings/interface-settings",
      name: Tabs.Settings.InterfaceSettings,
      meta: { title: "Настройки функциональности", requiresAuth: true },
      component: () => import("@/views/settings/interfaceSettings/InterfaceSettings.vue"),
    },
    {
      path: "/settings/tags",
      name: Tabs.Settings.TagsSettings,
      meta: { title: "Управление тегами", requiresAuth: true },
      component: () => import("@/views/settings/tags/TagsSettings.vue"),
    },
    {
      path: "/history-notifications",
      name: Tabs.NotificationHistory,
      meta: { title: "История уведомлений", requiresAuth: true },
      component: () => import("@/views/notificationHistory/Index.vue"),
    },
    {
      path: "/company/extend",
      name: Tabs.AdminPages.PageTariffChanger,
      meta: { title: "Продление тарифа", requiresAuth: true },
      component: () => import("@/views/pageTariffChanger/Index.vue"),
    },
    {
      path: "/admin/cms",
      name: Tabs.AdminPages.ManagersList,
      meta: { title: "Список сотрудников", requiresAuth: true },
      component: () => import("@/views/admin/Index.vue"),
    },
    {
      path: "/not-allowed",
      name: Tabs.PageNotAllowed,
      meta: { title: "Доступ ограничен", requiresAuth: true },
      component: () => import("@/views/pageNotAllowed/Index.vue"),
    },
    {
      path: "/blocked",
      name: Tabs.PageBlocked,
      meta: { title: "Доступ ограничен", requiresAuth: true },
      component: () => import("@/views/pageBlocked/Index.vue"),
    },
    {
      path: "/expired",
      name: Tabs.PageExpired,
      meta: { title: "Доступ ограничен", requiresAuth: true },
      component: () => import("@/views/pageTariffExpired/Index.vue"),
    },
    {
      path: "/:catchAll(.*)*",
      name: Tabs.PageNotFound,
      meta: { title: "Страница не найдена", requiresAuth: true },
      component: () => import("@/views/pageNotFound/Index.vue"),
    },
  ],
}
