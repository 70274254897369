<template lang="pug">
form-input-container(
  :required="!hideAsterisk && (!disabled && (required || asterisk))"
  :label="label"
  :message="innerMessage ? innerMessage : infoMessage"
  :message-type="innerMessage ? 'error' : infoMessage && 'info'"
)
  template(#warning)
    slot(name="form-warning")
  slot
    base-input(
      :disabled="disabled"
      :modelValue="modelValue"
      :clearable="clearable"
      :type="type"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      @update:modelValue="checkValidation"
      @blur:modelValue="$emit('blur:modelValue', $event)"
      @enter="$emit('enter')"
    )
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "ValidationRow",
  components: {
    BaseInput,
    FormInputContainer,
  },
  emits: [
    'correct',
    'error',
    'enter',
    'update:modelValue',
    'clickButton',
    'input',
    'blur:modelValue',
  ],
  props: {
    modelValue: {},
    // формат, в котором вернуть данные - число или строка
    // если число - то инпут тоже только числовой
    type: {
      type: String as PropType<'text'|'number'>,
      default: 'text',
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    infoMessage: {
      type: String,
      default: '',
    },
    // проверка на не пустое значение
    notEmpty: {
      type: Boolean,
      default: false,
    },
    // проверка на содержание заглавных
    containCapital: {
      type: Boolean,
      default: false,
    },
    // подходит ли под имейл
    typeEmail: {
      type: Boolean,
      default: false,
    },
    // подходит ли под телефон
    typePhone: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    customChecker: {
      type: Function,
      default: () => { return '' },
    },
    watchValidation: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    required: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const innerMessage = ref('');

    function inputValidatedString(value: string) {
      context.emit("update:modelValue", props.type === 'text' ? value : Number(value));
      context.emit("input", props.type === 'text' ? value : Number(value));
    }

    function checkValidation(value: string | any): void {
      if (!props.disabled) {

        // если можно пустое значение - то пропустить
        if (!props.notEmpty && [null, undefined, ''].includes(value)) {
          innerMessage.value = ''
          context.emit('correct')
          inputValidatedString(value)
          return
        }

        // валидация
        if (
          props.notEmpty && !checkNotEmpty(value) ||
          props.containCapital && !checkCapital(value) ||
          props.typeEmail && !checkEmail(value) ||
          props.typePhone && !checkPhone(value) ||
          props.custom && !checkCustom(value)
        ) {
          context.emit('error')
          inputValidatedString(value)
          return;
        }

        context.emit('correct')
        inputValidatedString(value)
      }
    }

    function checkNotEmpty(value: string | any): boolean {
      // return true if not empty
      if (![null, undefined, ''].includes(value)) {
        innerMessage.value = ''
        return true;
      } else {
        innerMessage.value = 'Обязательное поле'
        return false;
      }
    }

    function checkCapital(value: string): boolean {
      // return true if contains only lower case
      if (value === value.toLowerCase()) {
        innerMessage.value = ''
        return true;
      } else {
        innerMessage.value = 'Введенное значение не должно содержать заглавных'
        return false;
      }
    }

    function checkEmail(email: string): boolean {
      // return true if email correct
      const re = /\S+@\S+\.\S+/;
      const spaces = /\s/;
      if (re.test(email) && !spaces.test(email)) {
        innerMessage.value = ''
        return true;
      } else {
        innerMessage.value = spaces.test(email) ? 'Email не должен содержать пробелы' : 'Введен некорректный email'
        return false;
      }
    }

    function checkPhone(phone: string): boolean {
      const re = /^\+7\d{10}$/;
      const spaces = /\s/;
      if (re.test(phone) && !spaces.test(phone)) {
        innerMessage.value = ''
        return true;
      } else {
        innerMessage.value = spaces.test(phone) ? 'Телефон не должен содержать пробелы' : 'Введите телефон в формате +7XXXXXXXXXX'
        return false;
      }
    }

    function checkCustom(value: any) {
      innerMessage.value = props.customChecker(value)
      return !innerMessage.value
    }

    onMounted(() => {
      if (!props.required) {
        checkValidation(props.modelValue)
      }
    })

    watch(() => [props.modelValue, props.required], () => {
      if (props.watchValidation) {
        checkValidation(props.modelValue);
      }
    }, { deep: true });

    function validate() {
      checkValidation(props.modelValue)
    }

    return {
      innerMessage,
      checkValidation,
      validate,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/paddings';
@import '@/assets/styles/mixin/fonts';

.validation-row {
  width: 100%;
  @include column_with_space(6px);

  label {
    @include label-12-16;
  }
}

.danger_message {
  color: red;
  @include label-12-16;

}
.info_message {
  color: gray;
  @include label-12-16;
}
</style>
