import { ref } from "vue";
import { defineStore } from "pinia";
import { getDefaultAnalyticsIndustryForm } from "@/utils/getters/defaultSearchForm";
import { getDefaultSortingForm } from "@/utils/getters/defaultFilters";

import type { AnalyticIndustryFormI } from "@/stores/search/SearchFormInterface";

const IndustryFetchMode = {
  None: 'none',
  Template: 'byTemplate',
  Filters: 'newAnalyse',
} as const;

type SubTabIndustryType = "lots" | "customers" | "suppliers";
type FetchModeType = typeof IndustryFetchMode[keyof typeof IndustryFetchMode];

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useAnalyticsIndustryStore = defineStore('analyticsIndustryStore', () => {

  const mode = ref<SubTabIndustryType>('lots');
  const fetchMode = ref<FetchModeType>(IndustryFetchMode.None);

  const selectedTemplatesIds = ref<number[]>([]);
  const searchForm = ref<AnalyticIndustryFormI>(getDefaultAnalyticsIndustryForm());

  const sortingForm = ref({
    lots: getDefaultSortingForm(),
    customers: getDefaultSortingForm(),
    suppliers: getDefaultSortingForm(),
  })

  function resetSearchForm() {
    searchForm.value = getDefaultAnalyticsIndustryForm()
  }

  function resetSortingForm() {
    for (const key of Object.keys(sortingForm.value)) {
      sortingForm.value[key] = getDefaultSortingForm();
    }
  }

  function resetStore() {
    resetSearchForm()
    resetSortingForm()
    selectedTemplatesIds.value = []
    fetchMode.value = IndustryFetchMode.None
  }

  return {
    mode,
    fetchMode,
    selectedTemplatesIds,
    searchForm,
    sortingForm,
    resetSearchForm,
    resetSortingForm,
    resetStore,
  }
}, { persist: true })

export default useAnalyticsIndustryStore;
