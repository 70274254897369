import { ref } from "vue";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

type TenderServiceName =
  | 'application'
  | 'claim'
  | 'bankGuarantee'
  | 'credit'
  | 'signature'

const lot = ref<LotDetailInterface>();
const isOpen = ref<boolean>(false);

/** если услуга была заказана со страницы услуг */
const needReload = ref(false);

/** выбранная услуга, которую открывают в диалоге */
const selectedServiceId = ref<number>(1);

function getServiceId(service: TenderServiceName) {
  switch (service) {
    case 'application': return 1;
    case 'claim': return 10;
    case 'bankGuarantee': return 18;
    case 'credit': return 16;
    case 'signature': return 5;
    default: return 1;
  }
}

/** логика открытия диалога заказа услуг */
export function useTenderService(close?: Function) {

  function openTenderService(tender?: LotDetailInterface, service?: TenderServiceName) {
    if (tender) lot.value = tender;
    if (service) selectedServiceId.value = getServiceId(service)
    isOpen.value = true;
  }

  function closeTenderService() {
    isOpen.value = false;
    lot.value = undefined;
    selectedServiceId.value = 1;
    if (close) close();
  }

  return {
    lot,
    isOpen,
    needReload,
    selectedServiceId,
    openTenderService,
    closeTenderService,
  }
}
