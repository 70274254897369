import type { SimpleManagerI } from "@/stores/auth/UserInterface";

export function getFullName<T extends SimpleManagerI>(user: T) {
  return [user.surName, user.firstName, user.middleName].filter(Boolean).join(' ')
}

export function getNameInitials<T extends SimpleManagerI>(user: T) {
  return [user.surName, user.firstName?.slice(0, 1) + '.' || null, user.middleName?.slice(0, 1) + '.' || null].filter(Boolean).join(' ')
}

export function applyFullNameToUser<T extends SimpleManagerI>(user: T) {
  return {
    ...user,
    fio: getFullName(user),
  }
}

export function applyFullNameToList<T extends SimpleManagerI>(data: T[]) {
  return data.map((e: T) => applyFullNameToUser(e));
}
