import { Tabs } from "@/router/tabs";

export default defineNuxtPlugin({
  name: 'scroll',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.hook('page:finish', () => {
      if (nuxtApp._route.name !== Tabs.Landing) {
        if (document.getElementById("app-main")) {
          document.getElementById("app-main")!.scrollTo(0, 0);
        }
      }
    })
  }
});
