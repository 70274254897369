import { waitUserData } from "@/router/auth";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('beforeEach', from.name, ' -> ', to.name)
  const { storage } = useServiceStorage();

  if (!from.name) storage.onReload = true;
  if (storage.ignoreBeforeFetch) {
    storage.ignoreBeforeFetch = false;
    return
  }

  if (to.path.endsWith('.html')) {
    return navigateTo(to.path.replace(/.html$/, ''), { redirectCode: 301 });
  }

  // проверка на infinite loop navigation
  const redirect = await waitUserData(to, from)
  if (redirect && redirect !== to.name) return navigateTo(redirect)
});

