<template lang="pug">
inline-svg.ui-icon(
  :src="`/icons/${icon}.${extension}`"
  :height="size"
  :width="size"
  :title="title"
  :class="{_color: !originalColor}"
  :style="{ cursor: clickable && 'pointer' }"
)
</template>

<script lang="ts">
import InlineSvg from 'vue-inline-svg';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'UiIcon',
  components: {
    InlineSvg,
  },
  props: {
    extension: {
      type: String,
      default: "svg",
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 16,
    },
    originalColor: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss">
.ui-icon {
  flex-shrink: 0;

  &._color {
    path {
      fill: currentColor;
      /*stroke: currentColor;*/
    }
  }
}
</style>
