<template lang="pug">
form-input-container(
  label="Услуга"
  :required="required"
)
  el-select(
    filterable
    fit-input-width
    class="ui-select include-padding"
    value-key="id"
    placeholder="Выберите услугу"
    :teleported="teleported"
    :multiple="multiple"
    :clearable="clearable"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  )
    el-option-group(
      v-for="group of tenderServices"
      :key="group.title"
      :label="group.title"
    )
      el-option(
        v-for="item of group.consultingServices"
        :key="item.id"
        :label="item.title"
        :value="item.id"
      )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from "pinia";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "ServicesSelector",
  components: {
    FormInputContainer,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    teleported: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const manualsStore = useManualsStore();
    const { tenderServices } = storeToRefs(manualsStore);

    return {
      tenderServices,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/selector';
</style>
