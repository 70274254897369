import { ref, computed } from "vue";
import { useApi } from "@/use/api/useApi";
import { defineStore } from "pinia";
import { getDefaultLotsListSearchForm } from "@/utils/getters/defaultSearchForm";
import { getDefaultPreFiltrationForm } from "@/utils/getters/defaultFilters";
import { getDefaultTemplateData } from "@/utils/templates/templateFormatter";
import type { ExtendedTemplateI } from "@/stores/templates/SearchTemplatesInterface";
import type { SearchFormI } from "@/stores/search/SearchFormInterface";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";
import type { TenderTagI } from "~/stores/search/LotItemInterface";
import cloneDeep from "lodash/cloneDeep";

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useSearchStore = defineStore('searchStore', () => {

  const tags = ref<TenderTagI[]>([]);
  const needReload = ref(false);

  const searchPageMode = ref<'templateMode'|'fullSearchMode'|'simpleSearchMode'>('fullSearchMode');

  const tagsMapped = computed(() =>
    tags.value.reduce((res: any, item: TenderTagI) => {
      res[item.id] = item;
      return res;
    }, {})
  )

  /** FAST SEARCH */
  const searchFormDataFS = ref<SearchFormI>(getDefaultLotsListSearchForm());              // поисковая форма
  const preFiltrationFormFS = ref<PreFiltrationFormI>(getDefaultPreFiltrationForm());     // панель фильтров

  /** FILTER SEARCH */
  const searchFormDataFilters = ref<SearchFormI>(getDefaultLotsListSearchForm());              // поисковая форма
  const preFiltrationFormFilters = ref<PreFiltrationFormI>(getDefaultPreFiltrationForm());     // панель фильтров

  /** TEMPLATES SEARCH */
  const searchFormDataTemplates = ref<SearchFormI>(getDefaultLotsListSearchForm());              // поисковая форма
  const preFiltrationFormTemplates = ref<PreFiltrationFormI>(getDefaultPreFiltrationForm());     // панель фильтров

  const selectedTemplatesIds = ref<number[]>([]);     // number[] включая -2 если выбраны 'Закупки без шаблона'
  const selectedTemplateId = computed(() => selectedTemplatesIds.value.length === 1 && selectedTemplatesIds.value[0] !== -2 ? selectedTemplatesIds.value[0] : null);                     // id выбранного шаблона (исключая -2) или null

  const selectedTemplateMeta = ref<ExtendedTemplateI>(getDefaultTemplateData())
  const dateCreateFilter = ref();

  /** GETTER/SETTER functions */

  function setSelectedTemplateIds(templates: number[]) {
    selectedTemplatesIds.value = templates || [];
  }

  function resetFastSearchData() {
    searchFormDataFS.value = getDefaultLotsListSearchForm()
    preFiltrationFormFS.value = getDefaultPreFiltrationForm()
  }

  function resetTemplateData() {
    searchFormDataTemplates.value = getDefaultLotsListSearchForm()
    preFiltrationFormTemplates.value = getDefaultPreFiltrationForm()

    selectedTemplatesIds.value = [];
    selectedTemplateMeta.value = getDefaultTemplateData()
    dateCreateFilter.value = undefined
  }

  function resetSearchStore() {
    tags.value = [];
    needReload.value = false;
    searchPageMode.value = 'fullSearchMode';

    searchFormDataFilters.value = getDefaultLotsListSearchForm()
    preFiltrationFormFilters.value = getDefaultPreFiltrationForm()

    resetFastSearchData()
    resetTemplateData()
  }

  function getSearchFormCopy() {
    switch (searchPageMode.value) {
      case "simpleSearchMode": return cloneDeep(searchFormDataFS.value);
      case "fullSearchMode": return cloneDeep(searchFormDataFilters.value);
      case "templateMode": return cloneDeep(searchFormDataTemplates.value);
    }
  }

  function getPreFiltrationFormCopy() {
    switch (searchPageMode.value) {
      case "simpleSearchMode": return cloneDeep(preFiltrationFormFS.value);
      case "fullSearchMode": return cloneDeep(preFiltrationFormFilters.value);
      case "templateMode": return cloneDeep(preFiltrationFormTemplates.value);
    }
  }

  function updateSearchFormData(sf: SearchFormI) {
    switch (searchPageMode.value) {
      case "simpleSearchMode":
        searchFormDataFS.value = cloneDeep(sf);
        break;
      case "fullSearchMode":
        searchFormDataFilters.value = cloneDeep(sf);
        break;
      case "templateMode":
        searchFormDataTemplates.value = cloneDeep(sf);
        break;
    }
  }

  function updatePreFiltrationForm(pf: PreFiltrationFormI) {
    switch (searchPageMode.value) {
      case "simpleSearchMode":
        preFiltrationFormFS.value = cloneDeep(pf);
        break;
      case "fullSearchMode":
        preFiltrationFormFilters.value = cloneDeep(pf);
        break;
      case "templateMode":
        preFiltrationFormTemplates.value = cloneDeep(pf);
        break;
    }
  }

  function resetSearchFormData() {
    updateSearchFormData(getDefaultLotsListSearchForm())
  }

  function resetPreFiltrationFormData() {
    updatePreFiltrationForm(getDefaultPreFiltrationForm())
  }

  function getTenderTags(query = '') {
    return new Promise((resolve, reject) => {

      useApi().tags.fetchTenderTags(query)
        .then((hydraData: any) => {
          tags.value = hydraData['hydra:member']
          resolve(hydraData['hydra:member'])
        })
        .catch(() => {
          tags.value = []
          reject()
        })
    })
  }

  return {
    tags,
    tagsMapped,
    needReload,
    searchPageMode,

    searchFormDataFS,
    preFiltrationFormFS,

    searchFormDataFilters,
    preFiltrationFormFilters,

    searchFormDataTemplates,
    preFiltrationFormTemplates,

    selectedTemplatesIds,
    selectedTemplateId,
    selectedTemplateMeta,
    dateCreateFilter,

    getTenderTags,
    setSelectedTemplateIds,
    resetSearchStore,
    resetFastSearchData,
    resetTemplateData,
    getSearchFormCopy,
    getPreFiltrationFormCopy,
    updateSearchFormData,
    updatePreFiltrationForm,
    resetSearchFormData,
    resetPreFiltrationFormData,
  }
}, { persist: true })

export default useSearchStore;
