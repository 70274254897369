import { Tabs } from "@/router/tabs";
import useAuthStore, { awaitUser } from "@/stores/auth/useAuthStore";
import type { ManagerI } from "@/stores/auth/UserInterface";
import type { RouteLocationNormalized } from "vue-router";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import {
  availableForBlocked, availableForExpired,
  checkExpiredPlan,
  checkIsTabAvailable,
  checkIsTmManager,
  isAccountBlocked,
  managerHasAccess,
  noAccessMessage,
  redirectInAnalytics,
  redirectInAnalyticsInner,
  userHasAccess,
} from "@/utils/authorization/auth";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

interface UserInnerI {
  enabled: string[],
  isExpired: boolean,
  isBlocked: boolean,
  isAdmin: boolean,
}

let user : UserInnerI | null = null;

function redirectFromLanding(checkRedirect = false) {
  const isPlanExpired = !user?.isBlocked && checkExpiredPlan(user?.enabled);

  const isSearchAvailable = checkIsTabAvailable(Tabs.Search, user?.enabled)
  const isAnalyticsAvailable = checkIsTabAvailable(Tabs.Analytics.Main, user?.enabled)

  if (user?.isBlocked) return { name: Tabs.PageBlocked };
  else if (isPlanExpired) return { name: Tabs.PageExpired };

  if (checkIsTmManager(user?.enabled)) return { name: Tabs.Services };
  else {
    const { storage } = useServiceStorage();
    const redirect = storage.redirect;

    if (checkRedirect && redirect) {
      storage.redirect = '';
      if (!redirect.includes('expired') && !redirect.includes('blocked')) return redirect;
    }

    if (isSearchAvailable) return { name: Tabs.Search };
    else if (isAnalyticsAvailable) return redirectInAnalyticsInner(user?.enabled || []);
    else return { name: Tabs.Services};
  }
}

export function getMainPageNavigateRoute() : { name: string } {

  const isSearchAvailable = checkIsTabAvailable(Tabs.Search, user?.enabled || [])
  const isAnalyticsAvailable = checkIsTabAvailable(Tabs.Analytics.Main, user?.enabled || [])

  if (user?.isExpired) return { name: Tabs.PageExpired };
  if (user?.isBlocked) return { name: Tabs.PageBlocked };

  if (isSearchAvailable) return { name: Tabs.Search };
  else if (isAnalyticsAvailable) return redirectInAnalyticsInner(user?.enabled || []);
  else return { name: Tabs.Services};
}

function routeByAccess(to: RouteLocationNormalized) {
  // проверяем что юзер активный (не заблокирован и тариф активен)
  if (user?.isBlocked || user?.isExpired) {
    if (user?.isBlocked && availableForBlocked.includes(to.name)) return;
    if (user?.isExpired && availableForExpired.includes(to.name)) return;
    useNotificationsStore().showError('Доступ ограничен');
    return { name: Tabs.PageNotAllowed };
  }

  // если юзер - тм менеджер и у него нет доступа к странице
  if (user?.isAdmin && !managerHasAccess(to.name)) {
    noAccessMessage()
    return { name: Tabs.Services };
  }

  // если юзер - обычный юзер и у него нет доступа к странице
  if (!user?.isAdmin && !userHasAccess(to.name, user?.enabled || [])) return { name: Tabs.PageNotAllowed };
  if (to.name === Tabs.Analytics.Main) return redirectInAnalytics(user?.enabled)

  return
}

export function accountHasAccess(name: string) : boolean {
  if (user?.isBlocked) return availableForBlocked.includes(name);
  if (user?.isExpired) return availableForExpired.includes(name);
  if (user?.isAdmin) return managerHasAccess(name);
  return userHasAccess(name, user?.enabled || []);
}

function initUserFromStorage() {
  const authStore = useAuthStore()
  setLocalUser(authStore.me, authStore.enabled)
  return user;
}

export function resetLocalUser() {
  user = null
}

export function setLocalUser(me: ManagerI, roles: string[]) {
  if (me?.id) {
    const blocked = isAccountBlocked(me, roles || [])
    user = {
      enabled: roles || [],
      isBlocked: blocked,
      isExpired: !blocked && checkExpiredPlan(roles || []),
      isAdmin: checkIsTmManager(roles || [])
    }
  } else resetLocalUser()
}

export function redirectAfterLogin() {
  const router = useRouter();
  if (router.currentRoute.value.name === Tabs.Landing) {
    const redirect = redirectFromLanding(true)
    if (redirect) navigateTo(redirect)
  }
}

export async function waitUserData(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  // если пользователь не был авторизован (в storage нет его данных)
  if (!user && !initUserFromStorage()) {
    // если страница доступна всем - пропускать
    if (!to.meta.requiresAuth) return;
    else {
      // иначе ждем getUsersMe
      await awaitUser.value;
      initUserFromStorage();
    }
  }
  // иначе (если данные есть или нам пришел 200 на getUsersMe)
  if (user) return to.name === Tabs.Landing && (from.name === Tabs.Landing || from.name === undefined) ? redirectFromLanding() : routeByAccess(to)

  // если совсем ничего по юзеру нет и он хочет внутрь - отправлять на лендинг
  if (to.meta.requiresAuth) {
    const { storage } = useServiceStorage();
    storage.redirect = to.fullPath;
    return { name: Tabs.Landing };
  }

  return;
}
