import { defineStore } from "pinia";
import { ref } from "vue";
import { getDefaultSortingForm } from "@/utils/getters/defaultFilters";
import { getDefaultAnalyticsCustomersForm, getDefaultAnalyticsSuppliersForm } from "@/utils/getters/defaultSearchForm";

import type { AnalyticCustomerFormI } from "@/stores/search/SearchFormInterface";
import type { SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";

type SubTabCustomersType = "actual" | "completed" | "suppliers" | "participants";

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useAnalyticsCustomersStore = defineStore('analyticsCustomersStore', () => {

  const mode = ref<SubTabCustomersType>('completed');

  const selectedCompany = ref<SingleCompanyInterface | null>(null);
  const searchForm = ref<AnalyticCustomerFormI>(getDefaultAnalyticsCustomersForm());

  const sortingForm = ref({
    actual: getDefaultSortingForm(),
    completed: getDefaultSortingForm(),
    suppliers: getDefaultSortingForm(),
    participants: getDefaultSortingForm(),
  })

  function resetSortingForm() {
    for (const key of Object.keys(sortingForm.value)) {
      sortingForm.value[key] = getDefaultSortingForm();
    }
  }

  function resetSearchForm() {
    searchForm.value = getDefaultAnalyticsSuppliersForm()
  }

  function resetStore() {
    selectedCompany.value = null
    resetSearchForm()
    resetSortingForm()
  }

  return {
    mode,
    selectedCompany,
    searchForm,
    sortingForm,
    resetStore,
    resetSearchForm,
    resetSortingForm,
  }
}, { persist: true })

export default useAnalyticsCustomersStore;
