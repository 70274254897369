<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  .ui-textarea
    textarea.base-input.base-textarea(
      :spellcheck="spellcheck"
      :class="disabled && '_disabled'"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="modelValue"
      :maxlength="maxLength"
      @input="$emit('update:modelValue', $event.target.value)"
    )
    span.count(v-if="showWordLimit") {{ modelValue.length }} / {{ maxLength }}
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "FormTextarea",
  components: {
    FormInputContainer,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    spellcheck: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/baseInput";

.base-textarea {
  resize: vertical;
  width: 100%;
  height: 100px;
  min-height: 100px;
  max-height: 220px;
  line-height: 19px;
}

.base-textarea:focus-visible {
  outline: none;
  border-color: var(--input-border-color-hover);
}

.ui-textarea {
  display: flex;
  position: relative;
}

.count {
  color: #909399;
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  bottom: 4px;
  right: 12px;
}
</style>
