import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { Tabs } from "@/router/tabs";

import type { ContractsTab } from "@/router/tabs";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";
import useTemplatesStore from "~/stores/templates/useTemplatesStore";
import useSearchStore from "~/stores/search/useSearchStore";

function getDefaultPageData() {
  return {
    selectedTemplatesIds: [],
    preFiltrationForm: getDefaultPreFiltrationFormContract(),
  }
}

function getDefaultPagesData() {
  return {
    contracts: getDefaultPageData(),
    contractConclusion: getDefaultPageData(),
    contractExecution: getDefaultPageData(),
    contractCompleted: getDefaultPageData(),
  }
}

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useContractsStore = defineStore('contractsStore', () => {

  const tab = ref(Tabs.Contracts.Main);
  const pageStatuses = ref<number[]>([]);

  const updateContractStatusLoader = ref(false);

  const selectedTemplatesIds = ref<number[]>([]);  // number[] включая -2 если выбраны 'Закупки без шаблона'
  const preFiltrationForm = ref<PreFiltrationFormContractI>(getDefaultPreFiltrationFormContract());

  const pages = reactive(getDefaultPagesData())

  const searchStore = useSearchStore();
  const templatesStore = useTemplatesStore();

  function updatePages() {
    pages[tab.value].selectedTemplatesIds = selectedTemplatesIds.value;
    pages[tab.value].preFiltrationForm = preFiltrationForm.value;
  }

  function setDataOnMount(t: ContractsTab) {
    // если были выбраны удаленные шаблоны или теги, их нужно убрать
    pages[t].selectedTemplatesIds = pages[t].selectedTemplatesIds.filter(e => !!templatesStore.templatesList.find(t => t.id === e) || e === -2)
    pages[t].preFiltrationForm.tagIds = pages[t].preFiltrationForm.tagIds.filter(e => !!searchStore.tags.find(t => t.id === e))

    selectedTemplatesIds.value = pages[t].selectedTemplatesIds;
    preFiltrationForm.value = pages[t].preFiltrationForm;
  }

  function resetContractsStore() {
    tab.value = Tabs.Contracts.Main
    pageStatuses.value = [];
    selectedTemplatesIds.value = [];

    pages.contracts = getDefaultPageData()
    pages.contractCompleted = getDefaultPageData()
    pages.contractConclusion = getDefaultPageData()
    pages.contractExecution = getDefaultPageData()

    updateContractStatusLoader.value = false;
    preFiltrationForm.value = getDefaultPreFiltrationFormContract();
  }

  function getPageStatuses(t: ContractsTab) {
    switch (t) {
      case Tabs.Contracts.Main: return [];
      case Tabs.Contracts.Conclusion: return [1, 2, 3, 4];
      case Tabs.Contracts.Execution: return [5, 6, 7];
      case Tabs.Contracts.Completed: return [8, 9, 10];
    }
  }

  function setTab(t: ContractsTab) {
    tab.value = t;
    pageStatuses.value = getPageStatuses(t);
    setDataOnMount(t);
  }

  return {
    pages, // не удалять
    pageStatuses,
    selectedTemplatesIds,
    preFiltrationForm,
    updateContractStatusLoader,
    setTab,
    updatePages,
    resetContractsStore,
  }
}, { persist: true })

export default useContractsStore;
