<template lang="pug">
NuxtLayout
  NuxtPage
  //NuxtErrorBoundary
  //  NuxtPage
  //  template(#error)
  //    PageError(:with-layout="true")
system-alerts
system-notifications
tender-services-dialog
</template>

<script setup lang="ts">
import useAuthStore from "@/stores/auth/useAuthStore";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import SystemAlerts from "~/globals/notifications/SystemAlerts.vue";
import SystemNotifications from "~/globals/notifications/SystemNotification.vue";
import TenderServicesDialog from "~/globals/orders/TenderServicesDialog.vue";
import PageError from "~/views/pageError/Index.vue";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { computed } from "vue";

useAuthStore().getUsersMe()
useManualsStore().manuals.getTenderServices();

useNotificationsStore();

const route = useRoute();
const { isAuth, isTrial, isProduction } = useUserAccess();

const needVisor = computed(() => isProduction.value && (!isAuth.value || isTrial.value))

useHead(computed(() => needVisor.value ? {
  script: [
    {
      type: 'text/javascript',
      innerHTML: '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(39120155, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });'
    },
    {
      src: "https://www.googletagmanager.com/gtag/js?id=G-QKV1KJPBM9",
      async: true,
    },
    {
      type: 'text/javascript',
      innerHTML: 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-QKV1KJPBM9\');',
    }
  ]
} : {}))

useSeoMeta({
  title: computed(() => route?.meta?.title || 'ТендерМонитор - Система поиска и управления тендерами | Free Trial TM (2024)'),
})
</script>
