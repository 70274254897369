import { ref } from "vue";
import { defineStore } from "pinia";
import { useApi } from "@/use/api/useApi";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { checkIsTabAvailable } from "@/utils/authorization/auth";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import type { InterfaceSettingsI } from "@/stores/settings/SettingsInterfaces";
import type { MenuItemI } from "@/stores/settings/SettingsInterfaces";

import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";

/** saveFiltersOnRedirect - сохранение выбранных шаблонов */
function getDefaultFunctionsValue() {
  return {
    needClearBeforeSave: false,
    rememberClearSetting: false,
    autoLoadTemplates: false,
    saveFiltersOnReload: true,
    saveAnalyticsOnReload: true,
    saveFiltersOnRedirect: false,
    saveAnalyticsSortingOnFiltersChange: false,
    showDatesOnStatusChange: true,
  }
}

function getDefaultSearchSettingsValue() {
  return {
    hideActiveLotsInQuickSearch: false,
    hideActiveLotsInSearchByTemplates: false,
  }
}

function getSwitchableTabs() {
  return {
    application: true,                   // все заявки
    calculation: true,                   // - расчет заявок
    participation: true,                 // - подача заявки
    bargaining: true,                    // - торги
    contracts: true,                     // все контракты
    contractCompleted: true,             // - завершенные контракты
    contractConclusion: true,            // - контракты на заключении
    contractExecution: true,             // - контракты на исполнении
    training: true,                      // обучение
  }
}

const MenuList = [
  { key: Tabs.Search, icon: UiIconNames.Icon_Search, label: "Поиск", path: "/search" },
  { key: Tabs.Applications.Main, icon: UiIconNames.Icon_Description, label: "Заявки", path: "/application",
    child: [
      { key: Tabs.Applications.Calculation, icon: UiIconNames.Menu_Calculation, label: "Расчет заявки", path: "/application/calculation" },
      { key: Tabs.Applications.Participation, icon: UiIconNames.Menu_Filing, label: "Подача заявки", path: "/application/filling" },
      { key: Tabs.Applications.Bargaining, icon: UiIconNames.Menu_Gavel, label: "Торги", path: "/application/bargaining" },
    ]
  },
  { key: Tabs.Contracts.Main, icon: UiIconNames.Menu_Contract, label: "Контракты", path: "/contracts",
    child: [
      { key: Tabs.Contracts.Conclusion, icon: UiIconNames.Menu_Conclusion, label: "На заключении", path: "/contracts/conclusion" },
      { key: Tabs.Contracts.Execution, icon: UiIconNames.Menu_Van, label: "На исполнении", path: "/contracts/execution" },
      { key: Tabs.Contracts.Completed, icon: UiIconNames.Menu_Money, label: "Завершенные", path: "/contracts/completed" },
    ],
  },
  { key: Tabs.Analytics.Main, icon: UiIconNames.Landing_DataAnalysis, label: "Аналитика", path: "/analytics" },
  { key: Tabs.Services, icon: UiIconNames.Menu_Widgets, label: "Услуги", path: "/services", highlight: true },
  { key: Tabs.NotificationHistory, icon: UiIconNames.Icon_Notification, label: "История уведомлений", path: "/history-notifications" },
  { key: Tabs.Training.Main, icon: UiIconNames.Icon_School, label: "Обучение", path: "/support" },
  { key: Tabs.Settings.Main, icon: UiIconNames.Menu_CogWheel, label: "Настройки", path: "/settings" },
];

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useInterfaceSettingsStore = defineStore('interfaceSettingsStore',
  () => {

    /** лоты */
    const unseenMode = ref(1);

    /** функционал */
    const functions = ref(getDefaultFunctionsValue())
    const searchSettings = ref(getDefaultSearchSettingsValue())

    /** main menu switchable tabs */
    const switchableTabs = ref(getSwitchableTabs())

    const { managerCompanyId } = useSuperAccount();
    const {
      enabled,
      isTmManager,
      isBlocked,
      isPlanExpired,
    } = useUserAccess();

    const tabs = computed<MenuItemI[]>(() => MenuList.map(e => ({
      ...e,
      child: e.child ? e.child.map(c => ({ ...c, show: switchableTabs.value[c.key] ?? true })) : e.child,
      available: checkTabAvailable(e.key),
      show: switchableTabs.value[e.key] ?? true,
    })));

    function switchTab(tab: MenuItemI, value: boolean, parentKey?: string) {
      if (switchableTabs.value.hasOwnProperty(tab.key)) switchableTabs.value[tab.key] = value
      if (value && parentKey && switchableTabs.value.hasOwnProperty(parentKey)) switchableTabs.value[parentKey] = value
      if (!value && tab.child?.length) {
        tab.child.forEach(c => {
          if (switchableTabs.value.hasOwnProperty(c.key)) switchableTabs.value[c.key] = value
        })
      }
    }

    function updateSettings(settings: InterfaceSettingsI) {
      Object.keys(switchableTabs.value).forEach(key => {
        if (settings.mainMenu.hasOwnProperty(key)) switchableTabs.value[key] = settings.mainMenu[key]
      });
    }

    function updateFunctions(f: object) {
      Object.keys(functions.value).forEach((key) => {
        if (f.hasOwnProperty(key) && typeof f[key] === 'boolean') functions.value[key] = f[key]
      })
    }

    function updateSearchSettings(f: object) {
      Object.keys(searchSettings.value).forEach((key) => {
        if (f.hasOwnProperty(key) && typeof f[key] === 'boolean') searchSettings.value[key] = f[key]
      })
    }

    /** запрос настроек интерфейса */
    function getInterfaceSettings() {
      useApi().settings.users.fetchInterfaceSettings()
        .then((response: any) => {
          if (!response) return;
          updateSettings(response);
          updateUnseenMode(response?.unseenMode?.unseenMode || 1, false)
          updateFunctions(response?.functions)
          updateSearchSettings(response?.searchSettings)
        })
    }

    /** обновление настроек главного меню */
    function saveInterfaceSettings() {
      const menu = { mainMenu: switchableTabs.value };
      useApi().settings.users.patchInterfaceSettings(menu)
    }

    /** обновление настроек unseenMode */
    function updateUnseenMode(mode: number, send = true) {
      unseenMode.value = mode || 1;
      if (send) useApi().settings.users.patchInterfaceSettings({ unseenMode: { unseenMode: mode || 1 }})
    }

    /** обновление настроек функциональности */
    function updateFunctionsValue(label: string, value: any, send = true, defaultValue = false) {
      functions.value[label] = typeof value === 'boolean' ? value : defaultValue;
      if (send) useApi().settings.users.patchInterfaceSettings({ functions: { [label]: value }})
    }

    /** обновление настроек функциональности */
    function updateSearchSettingsValue(label: string, value: any, send = true, defaultValue = false) {
      searchSettings.value[label] = typeof value === 'boolean' ? value : defaultValue;
      if (send) return useApi().settings.users.patchInterfaceSettings({ searchSettings: { [label]: value }})
    }

    function checkTabAvailable(key: string) {
      const isAvailableForManager = !isBlocked.value && !!managerCompanyId.value
      const isAvailableForUser = !isBlocked.value && !isPlanExpired.value

      switch (key) {
        case Tabs.Search: return isTmManager.value ? isAvailableForManager : checkIsTabAvailable(Tabs.Search, enabled.value);
        case Tabs.Applications.Main: return isTmManager.value ? isAvailableForManager : checkIsTabAvailable(Tabs.Applications.Main, enabled.value);
        case Tabs.Contracts.Main: return isTmManager.value ? isAvailableForManager : checkIsTabAvailable(Tabs.Contracts.Main, enabled.value);
        case Tabs.Analytics.Main: return isTmManager.value ? !isBlocked.value : checkIsTabAvailable(Tabs.Analytics.Main, enabled.value);
        case Tabs.Settings.Main: return isTmManager.value ? isAvailableForManager : isAvailableForUser;

        case Tabs.Training.Main: return isTmManager.value ? !isBlocked.value : isAvailableForUser;
        case Tabs.Services: return isTmManager.value ? !isBlocked.value : isAvailableForUser;
        case Tabs.NotificationHistory: return isTmManager.value ? !isBlocked.value : false;
        default: return false;
      }
    }

    function resetStore() {
      unseenMode.value = 1;
      functions.value = getDefaultFunctionsValue();
      searchSettings.value = getDefaultSearchSettingsValue();
      switchableTabs.value = getSwitchableTabs();
    }

    return {
      tabs,
      unseenMode,
      functions,
      searchSettings,
      switchableTabs,
      updateFunctionsValue,
      updateSearchSettingsValue,
      updateUnseenMode,
      getInterfaceSettings,
      saveInterfaceSettings,
      switchTab,
      resetStore,
    };
  }, { persist: true },
);

export default useInterfaceSettingsStore;
