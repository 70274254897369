import type { TimezoneType } from "@/stores/manuals/ManualsInterface";
import type { TenderServiceI } from "@/use/tenderService/TenderServiceInterface";

export function getTimezonesList(): TimezoneType[] {
  return [
    { time: 'МСК - 1:00, UTC + 2:00', title: 'Восточноевпропейское время', value: -1 },
    { time: 'МСК + 0:00, UTC + 3:00', title: 'Московское время', value: 0 },
    { time: 'МСК + 1:00, UTC + 4:00', title: 'Третья часовая зона', value: 1 },
    { time: 'МСК + 2:00, UTC + 5:00', title: 'Четвертая часовая зона', value: 2 },
    { time: 'МСК + 3:00, UTC + 6:00', title: 'Пятая часовая зона', value: 3 },
    { time: 'МСК + 4:00, UTC + 7:00', title: 'Шестая часовая зона', value: 4 },
    { time: 'МСК + 5:00, UTC + 8:00', title: 'Седьмая часовая зона', value: 5 },
    { time: 'МСК + 6:00, UTC + 9:00', title: 'Восьмая часовая зона', value: 6 },
    { time: 'МСК + 7:00, UTC + 10:00', title: 'Девятая часовая зона', value: 7 },
    { time: 'МСК + 8:00, UTC + 11:00', title: 'Десятая часовая зона', value: 8 },
    { time: 'МСК + 9:00, UTC + 12:00', title: 'Одиннадцатая часовая зона', value: 9 },
    { time: 'МСК + 10:00, UTC + 13:00', value: 10 },
    { time: 'МСК + 11:00, UTC + 14:00', value: 11 },

    { time: 'МСК - 15:00, UTC - 12:00', title: 'Линия перемены даты', value: -15 },
    { time: 'МСК - 14:00, UTC - 11:00', value: -14 },
    { time: 'МСК - 13:00, UTC - 10:00', value: -13 },
    { time: 'МСК - 12:00, UTC - 9:00', value: -12 },
    { time: 'МСК - 11:00, UTC - 8:00', title: 'Североамериканское тихоокеанское время', value: -11 },
    { time: 'МСК - 10:00, UTC - 7:00', title: 'Горное время', value: -10 },
    { time: 'МСК - 9:00, UTC - 6:00', title: 'Центральноамериканское время', value: -9 },
    { time: 'МСК - 8:00, UTC - 5:00', title: 'Южноамериканское тихоокеанское время', value: -8 },
    { time: 'МСК - 7:00, UTC - 4:00', title: 'Атлантическое время', value: -7 },
    { time: 'МСК - 6:00, UTC - 3:00', title: 'Южноамериканское восточное время', value: -6 },
    { time: 'МСК - 5:00, UTC - 2:00', title: 'Среднеатлантическое время', value: -5 },
    { time: 'МСК - 4:00, UTC - 1:00', value: -4 },
    { time: 'МСК - 3:00, UTC + 0:00', title: 'Западноевропейское время', value: -3 },
    { time: 'МСК - 2:00, UTC + 1:00', title: 'Центральноевропейское время', value: -2 },
  ]
}

export function getDefaultServiceList(): TenderServiceI[] {
  return [
    {
      title: "Заявки и жалобы",
      consultingServices: [
        { title: "Подготовка заявки", id: 1 },
        { title: "Подготовка жалобы", id: 2 },
      ]
    },
  ]
}
