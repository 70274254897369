import type {
  AnalyticCustomerFormI,
  AnalyticIndustryFormI,
  AnalyticSuppliersFormI,
  BaseSearchFormInterface,
  SearchFormI,
  TabMode
} from "@/stores/search/SearchFormInterface";

export const getCommonForm = (): BaseSearchFormInterface => {
  return {
    lotTypeShortIds: [],
    lotLawIds: [],
    places: [],
    deliveryPlaceIds: [],
    datePublic: {
      dateFrom: null,
      dateTo: null,
    },
    cost: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    costApp: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    lotAuctionSitesIds: [],
    lotAuctionSitesGroups: [],
    withoutLotAuctionSite: false,
    supplierIds: [],
    customerIds: [],
    lotsSearch: {
      keywords: [],
      exceptionWords: [],
      deliveryPlace: [],
      expertSearch: '',
    },
    docsSearch: {
      deliveryPlace: [],
      keywords: [],
      exceptionWords: [],
    },
    wordSearchMode: 2,
    industrySearchMode: 2,
    industryIds: [],
    okpdCodes: [],
    winner: false,
  };
}

/** Поиск */
export const getDefaultLotsListSearchForm = (): SearchFormI => {
  return {
    ...getCommonForm(),
    lotStatusIds: [1],
    dateApplication: {
      dateFrom: null,
      dateTo: null,
    },
    allWords: true,
    notSmallBusiness: undefined,
    byWordCombination: false,
    strictCompliance: false,
    needOrganizationResponsibleIds: false,
    isZMO: undefined,
  };
};

/** Аналитика по индустриям */
export const getDefaultAnalyticsIndustryForm = (): AnalyticIndustryFormI => {
  return {
    ...getCommonForm(),
    allWords: true,
    notSmallBusiness: undefined,
    byWordCombination: false,
    strictCompliance: false,
    needOrganizationResponsibleIds: false,
  };
};

/** Аналитика по поставщикам */
export const getDefaultAnalyticsSuppliersForm = (): AnalyticSuppliersFormI => {
  return {
    ...getCommonForm(),
    inn: null,
    kpp: null,
    needAllFilials: true,
    supplierCompanyId: undefined,
  }
};

/** Аналитика по заказчикам */
export const getDefaultAnalyticsCustomersForm = (): AnalyticCustomerFormI => {
  return {
    ...getCommonForm(),
    inn: null,
    kpp: null,
    needAllFilials: true,
    customerCompanyId: undefined,
  }
};

export const getDefaultSearchForm = (tab: TabMode) => {
  switch (tab) {
    case 'AnalyseIndustry': return getDefaultAnalyticsIndustryForm();
    case 'AnalyseSuppliers': return getDefaultAnalyticsSuppliersForm();
    case 'AnalyseCustomers': return getDefaultAnalyticsCustomersForm();
    case 'LotsListSearch': return getDefaultLotsListSearchForm();
  }
}
