<template lang="pug">
ui-dialog(
  closable
  modal
  width="960px"
  :z-index="3"
  :visible-prop="isOpen"
  header="ТЕНДЕРНЫЕ УСЛУГИ"
  @content:hide="hideForm"
)
  template(#content)
    .create-service-dialog
      .services-list_wrapper
        base-input(clearable v-model="filterInput" placeholder="Поиск по услугам")
        .services-list
          div(v-for="serviceGroup of manualsStore.tenderServices" :key="'_group' + serviceGroup.title")
            .cons-group {{ serviceGroup.title }}
            .service-item(
              v-for="s of serviceGroup.consultingServices"
              :key="s.title"
              v-show="s.title.toLowerCase().includes(filterInput.toLowerCase())"
              :class="service === s.id && '_selected'"
              @click="changeService(s)"
            ) {{ s.title }}
      .service-order-form
        .service-title {{ tenderServicesGetter.find(e => e.id === service)?.title }}
        .lot-info(v-if="lot")
          lot-link.gov-ru-id(:id="lot.lotId" :number="lot.govRuId")
          .lot-title {{ lot.lotTitle }}
        services-selector.service-selector(required v-model="service" teleported)
        order-form(show-textarea v-model="form" ref="orderFormRef" @confirm="createOrder")
        agreement(mode="info")

  template(#actions)
    ui-button(type="secondary" @click.stop="hideForm") Отменить
    ui-button(:loader="isSaving" @click.stop="validate") Заказать
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useOrderForm } from "~/use/tenderService/useOrderForm";
import useManualsStore from "~/stores/manuals/useManualsStore";

import UiButton from "~/components/ui/button/UiButton.vue";
import OrderForm from "~/components/orderForm/OrderForm.vue";
import BaseInput from "~/components/ui/base/BaseInput.vue";
import UiDialog from "~/components/ui/dialog/UiDialog.vue";
import ServicesSelector from "~/components/selector/ServicesSelector.vue";
import Agreement from "~/components/authorization/dialogContent/Agreement.vue";
import LotLink from "~/components/ui/links/LotLink.vue";

import type { BaseObjectI } from "~/stores/manuals/ManualsInterface";

export default defineComponent({
  name: "TenderServices",
  components: {
    LotLink,
    Agreement,
    UiDialog,
    OrderForm,
    UiButton,
    BaseInput,
    ServicesSelector,
  },
  setup() {

    const manualsStore = useManualsStore();
    const { tenderServicesGetter } = storeToRefs(manualsStore);

    const filterInput = ref("");

    const {
      lot,
      form,
      isOpen,
      isSaving,
      service,
      orderFormRef,
      validate,
      hideForm,
      createOrder,
    } = useOrderForm();

    function changeService(s: BaseObjectI) {
      service.value = s.id;
    }

    return {
      lot,
      form,
      filterInput,
      service,
      isOpen,
      isSaving,
      orderFormRef,
      manualsStore,
      tenderServicesGetter,
      validate,
      changeService,
      createOrder,
      hideForm,
    };
  },
});
</script>

<style scoped lang="scss">
.create-service-dialog {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .service-order-form {
    display: flex;
    flex-flow: column;
    gap: 16px;

    width: 60%;

    .service-title {
      color: var(--main-color-black);
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .service-selector {
      display: none;
    }

    .gov-ru-id {
      float: left;
      margin-right: 8px;
    }

    .lot-title {
      color: var(--main-placeholder-color);
    }

    .lot-info {
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .services-list_wrapper {
    display: flex;
    flex-flow: column;
    gap: 12px;
    height: inherit;
    float: left;
    width: 34%;
    box-sizing: border-box;

    .service-item {
      display: flex;
      flex-flow: row;
      font-size: 14px;
      line-height: 16px;
      color: var(--main-color-black);
      cursor: pointer;
      padding: 12px 24px;

      &._selected {
        background: #F1F7FF;
        border-radius: 6px;

        font-weight: 500;
        color: var(--main-color-blue);
      }

      &:hover {
        color: var(--main-color-blue);
      }
    }

    .services-list {
      max-height: 400px;
      padding-right: 2px;
      overflow-y: auto;
    }
  }
}

.cons-group {
  font-size: 13px;
  line-height: 20px;
  margin: 8px 0;
  color: #9c9ea4;
}

@media (max-width: 960px) {
  .create-service-dialog {
    .services-list_wrapper { display: none; }

    .service-order-form {
      width: 100%;
      .service-selector { display: flex; }
      .service-title { display: none; }
    }
  }
}
</style>
