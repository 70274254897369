<template lang="pug">
.base-input(:class="disabled && '_disabled'" @click="focus")
  input(
    ref="baseInput"
    :type="type"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :placeholder="placeholder"
    :value="modelValue"
    :maxlength="maxLength"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="$emit('blur:modelValue', $event.target.value)"
    @keyup.enter="onEnter"
  )
  span.count(v-if="showWordLimit") {{ modelValue.length }} / {{ maxLength }}
  slot
    mini-loader.loader(v-if="loader" :size="12")
    icon-button.grey(
      v-if="(clearable && modelValue) || icon !== UiIconNames.Icon_CircleClose"
      :size="16"
      :icon="icon"
      :tooltip-title="iconTooltip"
      @click.stop="clickButton"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, ref } from "vue";

import IconButton from "~/components/ui/button/IconButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";

export default defineComponent({
  name: "BaseInput",
  components: {
    MiniLoader,
    IconButton,
  },
  emits: [
    'enter',
    'clickButton',
    'blur:modelValue',
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    type: {
      type: String as PropType<'number' | 'text' | 'password'>,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: UiIconNames.Icon_CircleClose,
    },
    iconTooltip: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const baseInput = ref(null);

    function focus() {
      baseInput.value.focus();
    }

    function blur() {
      baseInput.value.blur();
    }

    function onEnter() {
      blur()
      emit('enter')
    }

    function clickButton() {
      if (props.clearable) {
        emit('update:modelValue', props.type === 'number' ? null : '')
      }
      emit('clickButton')
    }

    return {
      baseInput,
      focus,
      blur,
      clickButton,
      onEnter,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/baseInput";

.base-input {
  display: flex;
  flex-flow: row;
  cursor: pointer;
  align-items: center;
  position: relative;

  input {
    width: 100%;
  }

  :deep(.grey) {
    color: #a8abb2 !important;
  }

  .loader {
    padding: 0 6px;
  }
}

.count {
  color: #909399;
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  bottom: 8px;
  right: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
