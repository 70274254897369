import { defineStore } from "pinia";
import { ref } from "vue";
import { getDefaultAnalyticsSuppliersForm } from "@/utils/getters/defaultSearchForm";
import { getDefaultSortingForm } from "@/utils/getters/defaultFilters";

import type { AnalyticSuppliersFormI } from "@/stores/search/SearchFormInterface";
import type { SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";

type SubTabSuppliersType = "won" | "participated" | "customers" | "competitors";

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useAnalyticsSuppliersStore = defineStore('analyticsSuppliersStore', () => {

  const mode = ref<SubTabSuppliersType>('won');

  const selectedCompany = ref<SingleCompanyInterface | null>(null);
  const searchForm = ref<AnalyticSuppliersFormI>(getDefaultAnalyticsSuppliersForm());

  const sortingForm = ref({
    won: getDefaultSortingForm(),
    participated: getDefaultSortingForm(),
    customers: getDefaultSortingForm(),
    competitors: getDefaultSortingForm(),
  })

  function resetSortingForm() {
    for (const key of Object.keys(sortingForm.value)) {
      sortingForm.value[key] = getDefaultSortingForm();
    }
  }

  function resetSearchForm() {
    searchForm.value = getDefaultAnalyticsSuppliersForm()
  }

  function resetStore() {
    selectedCompany.value = null
    resetSearchForm()
    resetSortingForm()
  }

  return {
    mode,
    selectedCompany,
    searchForm,
    sortingForm,
    resetStore,
    resetSearchForm,
    resetSortingForm,
  }
}, { persist: true })

export default useAnalyticsSuppliersStore;
