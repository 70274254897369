<template lang="pug">
transition-group.alerts-container(name="alert" tag="div")
  alert-message.alert(
    v-for="item in alerts"
    closable
    :key="item.id"
    :label="item.title"
    :message="item.message"
    type="error"
    @close="closeNotification(item.id)"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export default defineComponent({
  name: "Alerts",
  components: {
    AlertMessage,
  },
  setup() {
    const notificationsStore = useNotificationsStore();
    const { alerts } = storeToRefs(notificationsStore);

    function closeNotification(id: string) {
      notificationsStore.deleteAlert(id);
    }

    return {
      alerts,
      closeNotification,
    };
  },
});
</script>

<style scoped lang="scss">
.alerts-container {
  margin-left: auto;
  position: fixed;
  z-index: 9999;
  top: 64px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 8px;
  width: 100%;

  .alert-move,
  .alert-enter-active,
  .alert-leave-active {
    transition: all 0.3s ease;
  }

  .alert-enter,
  .alert-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  .alert {
    width: 640px;
  }
}
</style>
