<template lang="pug">
.form-input-container(:class="fitContent && 'fit'")
  label(v-if="label") {{ label }}
    span(v-if="required" style="color: var(--main-red-color); margin-left: 2px;") *
    slot(name="warning")
  slot
  span(
    v-if="message"
    :class="messageType === 'info' ? '_info' : '_error'"
  ) {{ message }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "FormInputContainer",
  props: {
    fitContent: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    required: {
      type: Boolean,
      default: false,
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixin/fonts';

.form-input-container {
  display: flex;
  flex-flow: column;
  gap: 6px;
  width: 100%;
  color: var(--main-text-color);
  font-size: 14px;
  line-height: 16px;

  &.fit {
    width: fit-content;
  }

  label {
    font-size: 12px;
    line-height: 17px;
    display: flex;
  }

  span {
    font-size: 12px;
    line-height: 17px;

    &._info {
      color: var(--main-text-color);
    }

    &._error {
      color: var(--main-red-color);
    }
  }
}
</style>
