import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import { storeToRefs } from "pinia";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { computed } from "vue";

export function useSuperAccount() {

  const { isTmManager } = useUserAccess();

  const tenmonManagerStore = useTmManagerStore();

  const managerCompanyId = computed(() => tenmonManagerStore.selectedCompanyInfo?.id || null)
  const managerCompanyTitle = computed(() => tenmonManagerStore.selectedCompanyInfo?.title || null)

  const managerCompanyInfo = computed(() => tenmonManagerStore.selectedCompanyInfo || null)

  function assignResourceId(data: any): any {
    if (isTmManager.value && managerCompanyId.value) {
      return {
        ...data,
        company: `/api/companies/${managerCompanyId.value}`,
      }
    } else {
      return data;
    }
  }

  function assignCompanyId(data: any): any {
    if (isTmManager.value && managerCompanyId.value) {
      return {
        ...data,
        companyId: managerCompanyId.value,
      }
    } else {
      return data;
    }
  }

  function assignUserId(id: number, data: any): any {
    if (isTmManager.value) {
      return {
        ...data,
        userId: id,
      }
    } else {
      return data;
    }
  }

  function getQueryUserId(id?: number): string {
    return isTmManager.value && id ? `?userId=${id}` : '';
  }

  function getQuery(): string {
    return isTmManager.value && managerCompanyId.value ? `?companyId=${managerCompanyId.value}` : '';
  }

  function getQueryDot(): string {
    return isTmManager.value && managerCompanyId.value ? `?company.id=${managerCompanyId.value}` : '';
  }

  function getTagsQuery(isDeleted = false): string {
    return isTmManager.value && managerCompanyId.value ? `?company.id=${managerCompanyId.value}&isDeleted=${isDeleted}` : '';
  }

  return {
    managerCompanyId,
    managerCompanyTitle,
    managerCompanyInfo,
    getQuery,
    getQueryDot,
    getTagsQuery,
    assignCompanyId,
    assignResourceId,
    assignUserId,
    getQueryUserId,
  }
}
