import { differenceInMonths, differenceInDays, format } from "date-fns";

/** сегодня */
export function getToday() {
  return formatDateDMY(new Date());
}

/** текущий год */
export function getYear() {
  return new Date().getFullYear();
}

/** кол-во дней от сегодняшнего дня до заданного */
export function getDaysUntil(date: string|Date): number {
  return getDifferenceDays(new Date(), date);
}

/** кол-во дней между двумя датами */
export function getDifferenceDays(earlier: string | Date, later: string | Date): number {
  return differenceInDays(
      new Date(later).setHours(0, 0, 0, 0),
      new Date(earlier).setHours(0, 0, 0, 0)
    );
}

/** кол-во полных месяцев между двумя датами */
export function getFullMonthsRange(earlier: Date, later?: Date) {
  return differenceInMonths(later ? later : Date.now(), earlier);
}

/** дата в формате день.месяц.год */
export function formatDateDMY(dateString: string | Date): string {
  return dateString ? format(dateString, 'dd.MM.yyyy') : 'Не указана';
}

/** дата и время в формате день.месяц.год часы:минуты */
export function formatDateTime(dateString: string | Date, timeDivider = ':'): string {
  return dateString ? format(dateString, `dd.MM.yyyy, HH${ timeDivider }mm`) : 'Дата неизвестна';
}

export function getDateTimeTimezone(d: string | Date, timezone: string) : object {
  const [ dateString, timeString ] = d ? d.split(d.includes('T') ? 'T' : ' ') : [undefined, undefined]
  const zone = Number(timezone) || 0
  return {
    date: dateString ? format(d, 'dd.MM.yyyy') : 'Не указана',
    time: timeString ? [format(d, 'HH:mm'), getTimezoneString(zone, false)].join(' ') : undefined,
  }
}

export function getTimezoneString(timezone: string|number, showNull = true) {
  const zone = Number(timezone) || 0
  return zone === 0 && !showNull ? 'МСК' : `МСК${ zone >= 0 ? '+' : ''}${ zone + ':00'}`
}
