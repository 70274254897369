enum UiIconNames {
  Chevron_ArrowDown = 'chevron/arrow-down',
  Chevron_ArrowLeft = 'chevron/arrow-left',
  Chevron_ArrowRight = 'chevron/arrow-right',
  Chevron_ArrowUp = 'chevron/arrow-up',
  Chevron_CircleLeftFill = 'chevron/circle-fill-left',
  Chevron_CircleRightFill = 'chevron/circle-fill-right',
  Chevron_CircleDown = 'chevron/icon-chevron-circle-down',
  Chevron_CircleUp = 'chevron/icon-chevron-circle-up',
  Chevron_Down = 'chevron/icon-chevron-down',
  Chevron_Left = 'chevron/icon-chevron-left',
  Chevron_Right = 'chevron/icon-chevron-right',
  Chevron_Up = 'chevron/icon-chevron-up',
  Chevron_DoubleLeft = 'chevron/double-arrow-left',
  Chevron_DoubleRight = 'chevron/double-arrow-right',

  Icon_AidKit = 'common/icon-aid-kit',
  Icon_Back = 'common/icon-back',
  Icon_Bell = 'common/icon-bell',
  Icon_Block = 'common/icon-block',
  Icon_Calendar = 'common/icon-calendar',
  Icon_CheckLanding = 'common/icon-check-landing',
  Icon_CheckUnderline = 'common/icon-check-underline',
  Icon_CircleClose = 'common/icon-circle-close',
  Icon_Clock = 'common/icon-clock',
  Icon_CloseBold = 'common/icon-close-bold',
  Icon_Close = 'common/icon-close',
  Icon_CloudDownload = 'common/icon-cloud-download',
  Icon_CloudUpload = 'common/icon-cloud-upload',
  Icon_Cog = 'common/icon-cog',
  Icon_Connection = 'common/icon-connection',
  Icon_Copy = 'common/icon-copy',
  Icon_Delete = 'common/icon-delete',
  Icon_DeleteSmoothed = 'common/icon-delete-smoothed',
  Icon_Description = 'common/icon-description',
  Icon_DocumentCheck = 'common/icon-document-check',
  Icon_DocumentCheck2 = 'common/icon-document-check-2',
  Icon_Dots = 'common/icon-dots',
  Icon_Download = 'common/icon-download',
  Icon_Edit = 'common/icon-edit',
  Icon_Exit = 'common/icon-exit',
  Icon_EyeClose = 'common/icon-eye-close',
  Icon_EyeOpen = 'common/icon-eye-open',
  Icon_Filter = 'common/icon-filter',
  Icon_Folder = 'common/icon-folder',
  Icon_FullScreen = 'common/icon-fullscreen',
  Icon_HelpCircle = 'common/icon-help-circle',
  Icon_Home = 'common/icon-home',
  Icon_Info = 'common/icon-info',
  Icon_InfoNew = 'common/icon-info-new',
  Icon_Question = 'common/icon-question',
  Icon_Refresh = 'common/icon-refresh',
  Icon_LineChart = 'common/icon-line-chart',
  Icon_Link = 'common/icon-link',
  Icon_List = 'common/icon-list',
  Icon_Loading = 'common/icon-loading',
  Icon_Lock = 'common/icon-lock',
  Icon_Mail = 'common/icon-mail',
  Icon_Map = 'common/icon-map',
  Icon_Minus = 'common/icon-minus',
  Icon_More = 'common/icon-more',
  Icon_Next = 'common/icon-next',
  Icon_NotificationSmall = 'common/icon-notification-small',
  Icon_Notification = 'common/icon-notification',
  Icon_Operation = 'common/icon-operation',
  Icon_Phone = 'common/icon-phone',
  Icon_Plus = 'common/icon-plus',
  Icon_School = 'common/icon-school',
  Icon_SearchByIndustry = 'common/icon-search-by-industry',
  Icon_SearchByWords = 'common/icon-search-by-words',
  Icon_Search = 'common/icon-search',
  Icon_Send = 'common/icon-send',
  Icon_Setup = 'common/icon-setup',
  Icon_SortDown = 'common/icon-sort-down',
  Icon_SortUp = 'common/icon-sort-up',
  Icon_SortDownArrow = 'common/icon-sort-down-arrow',
  Icon_SortUpArrow = 'common/icon-sort-up-arrow',
  Icon_Sort = 'common/icon-sort',
  Icon_StarO = 'common/icon-star-o',   // rename 0 to outline
  Icon_Star = 'common/icon-star',
  Icon_Support = 'common/icon-support',
  Icon_Tag = 'common/icon-tag',
  Icon_Timer = 'common/icon-timer',
  Icon_Unlock = 'common/icon-unlock',
  Icon_User = 'common/icon-user',

  Landing_Chat = 'landing/landing-chat',
  Landing_ChatPhone = 'landing/landing-chat-phone',
  Landing_DataAnalysis = 'landing/landing-data-analysis',
  Landing_MoneyBag = 'landing/landing-money-bag',
  Landing_Monitor = 'landing/landing-monitor',
  Landing_NewOrders = 'landing/landing-new-orders',
  Landing_Suitcase = 'landing/landing-suitcase',
  Landing_Trophy = 'landing/landing-trophy',

  Menu_Calculation = 'menu/icon-menu-calculation',
  Menu_CogWheel = 'menu/icon-menu-cog',
  Menu_Conclusion = 'menu/icon-menu-conclusion',
  Menu_Contract = 'menu/icon-menu-contract',
  Menu_Filing = 'menu/icon-menu-filing',
  Menu_Gavel = 'menu/icon-menu-gavel',
  Menu_Main = 'menu/icon-menu',
  Menu_Money = 'menu/icon-menu-money',
  Menu_Van = 'menu/icon-menu-van',
  Menu_Widgets = 'menu/icon-menu-widgets',

  Settings_Brush = 'settings/settings-brush',
  Settings_Cube = 'settings/settings-cube',
  Settings_Group = 'settings/settings-group',
  Settings_Office = 'settings/settings-office',
  Settings_Account = 'settings/settings-profile',
  Settings_CogWheels = 'settings/settings-settings',
  Settings_Notification = 'settings/settings-sms',

  SocialMedia_Facebook = 'social-media/facebook',
  SocialMedia_Google = 'social-media/google',
  SocialMedia_Instagram = 'social-media/instagram',
  SocialMedia_Mail = 'social-media/mail',
  SocialMedia_Telegram = 'social-media/telegram',
  SocialMedia_Vkontakte = 'social-media/vk',
  SocialMedia_Whatsapp = 'social-media/wa',
  SocialMedia_Yandex = 'social-media/yandex',
}

export default UiIconNames;
