import { computed, ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { defineStore } from "pinia";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { storeToRefs } from "pinia";
import {
  getTemplateInfoBasic,
  getTemplateInfoExtended
} from "@/utils/templates/templateFormatter";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import useSearchStore from "@/stores/search/useSearchStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

import type { LotItemInterface } from "@/stores/search/LotItemInterface";
import type { CreateGroupI, CreateTemplateI } from "@/use/api/ApiInterface";
import type {
  GroupI,
  UnseenI,
  ExtendedTemplateI, TemplateI,
} from "@/stores/templates/SearchTemplatesInterface";

function getPostTemplateData(meta: ExtendedTemplateI, data: any) : CreateTemplateI {
  return {
    sort: 1,
    title: meta.title,
    type: "/api/templates-types/" + 1,
    content: JSON.stringify(data),
    group: meta.groupId ? `/api/templates-groups/${meta.groupId}` : null,
    responsiblePerson: meta.responsible ? `api/users/${ meta.responsible.id }` : null,
    mailingSchedule: meta.templateMailingSchedule ? meta.templateMailingSchedule.map(s => '/api/mailing-schedule/' + s) : [],
  };
}

function getPatchTemplateData(meta: ExtendedTemplateI, data: any, metaFieldsOnly = false) {
  let payloadBackend = {}

  if (data) payloadBackend = { content: JSON.stringify(data) }
  if (meta) {
    payloadBackend = {
      ...payloadBackend,
      title: meta.title,
      group: meta.groupId ? `/api/templates-groups/${ meta.groupId }` : (metaFieldsOnly && meta.hasOwnProperty('groupId') ? null : undefined),
      responsiblePerson: meta.responsible ? `api/users/${ meta.responsible.id }` : (metaFieldsOnly && meta.hasOwnProperty('responsible') ? null : undefined),
      mailingSchedule: meta.templateMailingSchedule ? meta.templateMailingSchedule.map(s => '/api/mailing-schedule/' + s) : (metaFieldsOnly && meta.hasOwnProperty('templateMailingSchedule') ? [] : undefined),
    }
  }

  return payloadBackend;
}

const useTemplatesStore = defineStore('templatesStore', () => {

  const templatesList = ref<TemplateI[]>([])
  const groupsList = ref<GroupI[]>([])

  const { isTmManager } = useUserAccess()
  const { assignResourceId } = useSuperAccount()

  const searchStore = useSearchStore()
  const { searchFormDataTemplates } = storeToRefs(searchStore)

  /** map для списка шаблонов - чтобы можно было быстро найти шаблон без метода find */
  const templatesMap = computed(() =>
    templatesList.value.reduce((res, item) => {
      res[item.id] = item;
      return res;
    }, {})
  );

  /** установить unseen для шаблона - используется после редактирования шаблона (ставит кол-во найденных лотов в unseen) */
  function setTemplateUnseen(id: number, unseen: number) {
    const template = templatesList.value.find(t => t.id === id);
    if (template) template.unseen = unseen;
  }

  /** отметка лота просмотренным, плюс берем все шаблоны, которым принадлежит лот, и уменьшаем unseen */
  function markViewed(lot: LotItemInterface) {
    lot.IsSeen = 'seen';
    lot.tmLot?.templates?.forEach(e => {
      if (templatesMap.value && templatesMap.value[e.id]) templatesMap.value[e.id].unseen -= 1;
    })
  }

  /** обработка response после редактирования шаблона */
  function onPatchResponse(meta: ExtendedTemplateI, data: any, response: any) {
    // если мы редактируем мета данные
    if (meta) {
      const index = templatesList.value.findIndex(t => t.id === meta.id)
      if (index !== -1) templatesList.value[index] = getTemplateInfoBasic(response, response?.group?.id || 0, templatesList.value[index].unseen)
    }
    // если редактируем фильтры
    if (data) searchFormDataTemplates.value = data
  }

  /**
   * GET для получения списка сохраненных шаблонов
   */
  async function getAllTemplates(query: string) {
    useApi().searchTemplates.fetchGroups(query)
      .then((response: any) => {
        let responseData = response || []
        responseData.push({ id: 0, title: 'Шаблоны без группы' });
        groupsList.value = responseData.map((group: any) => ({ id: group.id, title: group.title }));
      })

    return useApi().searchTemplates.fetchTemplates(query)
      .then((response: any) => {
        const responseData = response || [];
        // groupsList.value = responseData.map((group: any) => ({ id: group.id, title: group.title }));

        templatesList.value = responseData.reduce((res, group: any) => {
          res.push(...group.templates.map((template: any) => getTemplateInfoBasic(template, group.id, templatesMap.value[template.id]?.unseen || 0)));
          return res
        }, []);

        if (!isTmManager.value) getCountsUnseen(query)
      })
  }

  /**
   * GET для получения количества непросмотренных лотов в каждом из шаблонов
   */
  function getCountsUnseen(query: string) {
    useApi().searchTemplates.fetchCountUnseen<UnseenI[]>(query)
      .then((data) => {
        data?.forEach((e: UnseenI) => {
          if (templatesMap.value[e.templateId]) templatesMap.value[e.templateId].unseen = e.count;
        })
      })
  }

  /**
   * action сохранения или копирования шаблона
   */
  function postTemplateMeta(payload: { meta: ExtendedTemplateI, data: any }) {
    const payloadBackend: CreateTemplateI = assignResourceId(getPostTemplateData(payload.meta, payload.data));

    return new Promise<ExtendedTemplateI>((resolve, reject) => {

      useApi().searchTemplates.postTemplate(payloadBackend)
        .then((data: any) => {
          templatesList.value.push(getTemplateInfoBasic(data, payload.meta.groupId || 0))
          resolve(getTemplateInfoExtended(data))
        })
        .catch(() => {
          useNotificationsStore().showError('Возникла ошибка при сохранении шаблона')
          reject()
        })
    });
  }

  /**
   * action обновления шаблона
   */
  function patchTemplateMeta(payload: { id: number, meta?: ExtendedTemplateI, data?: any, needClear: boolean }) {
    if (payload.needClear) return innerPatchContent(payload)
    else return innerPatchTemplate(payload)
  }

  function innerPatchTemplate(payload: { id: number, meta?: ExtendedTemplateI, data?: any, metaFieldsOnly?: boolean }) {

    const payloadBackend = getPatchTemplateData(payload.meta, payload.data, payload.metaFieldsOnly)

    return new Promise((resolve, reject) => {
      useApi().searchTemplates.patchTemplate(payload.id, payloadBackend)
        .then((response) => {
          onPatchResponse(payload.meta, payload.data, response)
          resolve(getTemplateInfoExtended(response))
        })
        .catch(() => {
          useNotificationsStore().showError('Возникла ошибка при редактировании шаблона')
          reject()
        })
    })
  }

  function innerPatchContent(payload: { meta: ExtendedTemplateI, data: any }) {
    return new Promise((resolve, reject) => {

      useApi().searchTemplates.deleteTemplate(payload.meta.id)
        .then(() => {
          const payloadBackend: CreateTemplateI = assignResourceId(getPostTemplateData(payload.meta, payload.data));

          useApi().searchTemplates.postTemplate(payloadBackend)
            .then((data) => {
              onPatchResponse(payload.meta, payload.data, data)
              resolve(getTemplateInfoExtended(data))
            })
            .catch(() => reject(null))

        })
        .catch(() => reject(null))
    })
  }

  /**
   * action для удаления группы / шаблона
   */
  function deleteMeta(id: number, isGroup = false, showNotification = false) {

    function onDelete() {
      if (isGroup) {
        templatesList.value = templatesList.value.filter(t => t.groupId !== id)
        groupsList.value = groupsList.value.filter(g => g.id !== id);
      } else {
        templatesList.value = templatesList.value.filter(t => t.id !== id)
      }
    }

    return new Promise((resolve, reject) => {
      (isGroup
        ? useApi().searchTemplates.deleteGroup(id)
        : useApi().searchTemplates.deleteTemplate(id))
          .then(() => {
            onDelete()
            if (showNotification) useNotificationsStore().showSuccess(isGroup ? 'Группа шаблонов удалена' : 'Шаблон удален')
            resolve(null)
          })
          .catch(() => {
            if (showNotification) useNotificationsStore().showError(isGroup ? 'Возникла ошибка при удалении группы шаблонов' : 'Возникла ошибка при удалении шаблона')
            reject()
          })
    })
  }

  /**
   * action для создания новой группы шаблонов
   */
  function createGroupMeta(title: string) {
    return new Promise((resolve, reject) => {

      const payload: CreateGroupI = assignResourceId({ title: title })

      useApi().searchTemplates.postGroup<GroupI>(payload)
        .then((data) => {
          if (data?.id) {
            const group: GroupI = { id: data?.id, title: title };
            groupsList.value.push(group)
            resolve(group)
          } else reject(null)
        })
        .catch(() => reject(null))
    })
  }

  /**
   * action для редактирования группы шаблонов
   */
  function patchGroupMeta(id: number, title: string) {
    return new Promise((resolve, reject) => {

      useApi().searchTemplates.patchGroup<GroupI>(id, { title: title })
        .then(() => {
          const g = groupsList.value.find(e => e.id === id);
          if (g) g.title = title;
          resolve(id)
        })
        .catch(() => reject(null))
    })
  }

  function resetStore() {
    templatesList.value = []
    groupsList.value = []
  }

  return {
    templatesList,
    groupsList,
    setTemplateUnseen,
    markViewed,
    resetStore,
    getAllTemplates,
    postTemplateMeta,
    patchTemplateMeta,
    createGroupMeta,
    patchGroupMeta,
    deleteMeta,
  }
}, { persist: true })

export default useTemplatesStore;
