import ElementPlus, { ID_INJECTION_KEY } from "element-plus";
import locale from "element-plus/dist/locale/ru.js";

export default defineNuxtPlugin({
  name: 'element-plus',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.use(ElementPlus, { locale });
    nuxtApp.vueApp.provide(ID_INJECTION_KEY, {
      prefix: Math.floor(Math.random() * 10000),
      current: 0,
    });
  }
});
